import React, { useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import clsx from 'clsx';
import CustomLoader from '../CustomLoader';
import { toLongDateTimeString } from '../../tools/dateMethods';
import DropdownMenu from '../DropdownMenu';
import { useLocation } from 'wouter';
import { useSetRecoilState } from 'recoil';
import { toastDataAtom } from '../../tools/Atoms/atoms';
import { ToastVariant } from '../../types';
import { StoreStatusEnum } from '../../tools/constants';
import axiosInstance from '../../helper/axios';
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: any;
  numeric: boolean;
}
interface InterviewTableProps {
  filterByStatus?: string;
}

const EnhancedTableHead = () => {
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'age',
      numeric: false,
      disablePadding: true,
      label: 'Age'
    },
    {
      id: 'gender',
      numeric: true,
      disablePadding: false,
      label: 'Gender'
    },
    {
      id: 'telephone',
      numeric: false,
      disablePadding: false,
      label: 'Telephone'
    },
    {
      id: 'virtualConsultationTime',
      numeric: false,
      disablePadding: false,
      label: 'Virtual Consultation Time'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Doctor Review Status'
    },
    {
      id: 'reviewedBy',
      numeric: false,
      disablePadding: false,
      label: 'Reviewed By'
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions'
    }
  ];

  return (
    <thead className="bg-ilara-grey-light py-1 h-9 border-b">
      <tr>
        {headCells.map((headCell, index, arr) => (
          <th
            className={clsx(
              'text-xs font-medium text-ilara-lable-placeholder',
              index === 0 ? 'pl-8' : '',
              index === arr.length - 1 ? 'text-right pr-6' : 'text-left'
            )}
            key={index}
          >
            <p
              className={clsx(
                'text-ilara-label-placeholder',
                headCell.label === 'Expense date' && 'text-center',
                'opacity-100'
              )}
            >
              {headCell.label}
            </p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const InterviewTable = ({ filterByStatus }: InterviewTableProps) => {
  const [, setLocation] = useLocation();
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const setToastData = useSetRecoilState(toastDataAtom);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        axiosInstance
          .get(
            filterByStatus
              ? `/interviews/getAllInterviews/?page=${page}&size=${rowsPerPage}&status=${filterByStatus}`
              : `/interviews/getAllInterviews/?page=${page}&size=${rowsPerPage}`
          )
          .then((response: any) => {
            setIsLoading(false);
            setData(response?.data);
          })
          .catch(() => {
            // If login failed
            setIsLoading(false);
            // Handle error, maybe display an error message
            setToastData({
              open: true,
              variant: ToastVariant.error,
              title: 'Error',
              description: 'Some error occured while fetching interviews!'
            });
          });
      } catch (error) {
        setToastData({
          open: true,
          variant: ToastVariant.error,
          title: 'Error',
          description: 'Some error occured while fetching interviews!'
        });
      }
    };

    fetchData(); // Call the fetchData function when component mounts or when page/rowsPerPage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, filterByStatus]);
  return (
    <div className=" mobile:m-2 w-full">
      {isLoading ? (
        <CustomLoader type="linear" />
      ) : (
        <>
          {data?.interviews?.length ? (
            <div className="min-w-[100%] max-w-[80vw] overflow-x-auto">
              <table aria-labelledby="tableTitle" className=" border w-full min-w-[991px]">
                <EnhancedTableHead />
                <tbody>
                  {data?.interviews?.map((element: any, index: number) => {
                    return (
                      <tr
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className="hover:bg-ilara-grey-light border-b"
                      >
                        <td className="py-3 pl-8">
                          <p className="text-sm text-left font-medium">{element?.user?.name}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">{element?.user?.age}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-left font-medium text-sm">{element?.user?.gender}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            +{element?.user?.contactNumber}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {toLongDateTimeString(new Date(element?.createdAt))}
                          </p>
                        </td>
                        <td className="py-3">
                          <span
                            className={`inline-block ${
                              element?.status === StoreStatusEnum?.PENDING
                                ? 'bg-ilara-red'
                                : 'bg-ilara-blue'
                            } text-white px-3 py-1 rounded-full text-xs font-semibold tracking-wide uppercase`}
                          >
                            {element?.status}
                          </span>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {element?.reviewedBy ? element?.reviewedBy : 'N/A'}
                          </p>
                        </td>
                        <td align="right" className="py-3 pr-4">
                          <div className="pr-2">
                            <DropdownMenu
                              items={[
                                {
                                  name: 'View Details',
                                  onClick: () => {
                                    setLocation(
                                      `symptomDetails?interviewId=${element?.interviewId}`
                                    );
                                  }
                                }
                              ]}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex jsutify-center mt-[20px]">
              {/* <NoDataPage
                showBtn={true}
                buttonText="Add expenses"
                onClick={() => toggleAddExpenseModalVisible()}
              ></NoDataPage> */}
              No Data Available
            </div>
          )}
          {data?.totalInterviews > 0 && (
            <TablePagination
              component="div"
              count={data?.totalInterviews}
              page={page}
              rowsPerPageOptions={[10, 20, 50]}
              onPageChange={(_, next) => {
                setPage(next);
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(Number(event.target.value));
                setPage(0);
              }}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InterviewTable;
