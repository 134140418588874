import makeStyles from '@mui/styles/makeStyles';
import { createStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const useSharedStyles = makeStyles(() =>
  createStyles({
    header: {
      fontFamily: 'var(--Secondary-Black)',
      color: 'var(--ilara-dark)',
      fontSize: '24px',
      letterSpacing: '-0.29px',
      lineHeight: '32px',
      fontVariant: 'normal',
      fontStyle: 'normal'
    },
    subHeader: {
      fontFamily: 'var(--Secondary-Bold)',
      color: 'var(--ilara-dark)',
      fontSize: '18px',
      letterSpacing: '-0.22px',
      lineHeight: '24px',
      fontVariant: 'normal',
      fontStyle: 'normal'
    },
    textField: {
      color: 'inherit',
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FFFFFF',
        padding: 0,
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        '&.Mui-error': {
          borderColor: 'var(--ilara-red)',
          borderRadius: 4,
          '&:focus': {
            borderColor: 'var(--ilara-red)'
          }
        }
      },
      '& .MuiTextField-root': {
        position: 'relative',
        border: `1px solid var(--ilara-grey-mid)`,
        fontFamily: 'var(--Primary-Font)',
        fontWeight: 500,
        fontSize: 14,
        '&:focus': {
          border: '2px solid var(--ilara-blue)',
          color: 'var(--ilara-dark)'
        },
        '&:hover': {
          background: 'var(--ilara-grey-light)'
        }
      },
      width: '100%'
    },
    input: {
      '& .MuiInputBase-input': {
        position: 'relative',
        border: '1.5px solid var(--ilara-grey-mid)',
        fontFamily: 'var(--Primary-Font)',
        fontWeight: 500,
        background: 'var(--ilara-white)',
        fontSize: 14,
        padding: '8px 16px',
        '&:focus': {
          border: '1.5px solid var(--ilara-blue)',
          color: 'var(--ilara-dark)'
        },
        '&:hover': {
          background: 'var(--ilara-grey-light)'
        }
      },
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          borderColor: 'var(--ilara-red)',
          borderRadius: 4,
          '&:focus': {
            borderColor: 'var(--ilara-red)'
          }
        }
      }
    },

    helpertext: {
      letterSpacing: '-0.14px',
      color: 'var(--ilara-red)',
      fontFamily: 'var(--Primary-Font)',
      fontWeight: 500,
      fontSize: 12,
      marginLeft: 0
    },
    helpertextDate: {
      letterSpacing: '-0.14px',
      color: 'var(--ilara-red)',
      fontFamily: 'var(--Primary-Font)',
      fontWeight: 500,
      fontSize: 12,
      marginLeft: 0
    },

    searchResult: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'var(--Primary-Font)',
      marginRight: '8px'
    },
    textarea: {
      '& .MuiInputBase-input': {
        position: 'relative',
        border: '1px solid var(--ilara-grey-mid)',
        fontWeight: 500,
        fontSize: 14,
        padding: '8px 16px',
        '&:focus': {
          border: '2px solid var(--ilara-grey-blue)',
          color: 'var(--ilara-dark)'
        },
        '&:hover': {
          background: 'var(--ilara-grey-light)'
        }
      },
      '&.MuiFormControl-root': {
        border: 'none',
        padding: 0
      },
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          borderColor: 'var(--ilara-red)',
          borderRadius: 4,
          '&:focus': {
            borderColor: 'var(--ilara-red)'
          }
        }
      }
    },

    tooltip: {
      color: '#000',
      textTransform: 'capitalize',
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'var(--Primary-Font)',
      display: 'flex',
      padding: 0,
      minWidth: '20px'
    },

    checkboxForm: {
      alignItems: 'start',
      '& .MuiFormControlLabel-label': {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 500,
        color: 'var(--ilara-dark)',
        marginLeft: '12px',
        letterSpacing: '-0.17px',
        fontFamily: 'var(--Primary-Font)',
        '& .MuiCheckbox-root': {
          margin: theme.spacing(0.75, 1.5, 0.75)
        }
      },
      paddingBottom: '10px'
    },
    checkbox: {
      borderRadius: 4,
      height: 20,
      width: 20,
      stroke: '#696E7C',
      strokeWidth: 0.1,
      '&.MuiCheckbox-colorPrimary.Mui-checked': {
        color: 'var(--ilara-blue)'
      },
      '&.MuiCheckbox-root.Mui-disabled': {
        color: '#A7A7AB'
      },
      '&.MuiCheckbox-indeterminate': {
        color: 'var(--ilara-blue)'
      }
    },
    table: {
      borderCollapse: 'separate',
      '& .MuiTableCell-root:nth-child(2)': {
        width: 40
      },
      '& .MuiTableCell-root:nth-child(3)': {
        width: 240
      },
      '& .MuiTableCell-root:nth-last-child(2)': {
        width: 170
      },
      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-root': {
          // borderBottom: '1px solid var(--ilara-grey-mid)'
        }
      }
    },
    tableRow: {
      '&:hover': {
        backgroundColor: '#E3E3E3'
      }
    },
    actions: {
      display: 'flex',
      [theme.breakpoints.down(768)]: {
        justifyContent: 'space-between',
        width: '100%',
        paddingBottom: '16px'
      }
    }
  })
);

export const phoneInputStyles = makeStyles(() =>
  createStyles({
    phoneInput: (props: { focus?: boolean; error?: string }) => ({
      border:
        props.focus === true
          ? '1.5px solid var(--ilara-blue)'
          : '1.5px solid var(--ilara-grey-mid)',
      borderRadius: '4px',
      padding: '8px 16px',
      color: props.focus === true && 'var(--ilara-dark)',
      borderColor: 'var(--ilara-grey-mid)'
    }),
    phoneInputErr: (props: { focus?: boolean; error?: string }) => ({
      border: props.error ? ' 1.5px solid var(--ilara-red)' : '1.5px solid var(--ilara-grey-mid)',
      borderRadius: '4px',
      padding: '8px 16px',
      borderColor: 'var(--ilara-grey-mid)'
    })
  })
);
