import Cookies from 'js-cookie';

interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
}

export const setCookie = (name: string, value: string, options: CookieOptions = {}): void => {
  const expirationTime = 8 * 60 * 60 * 1000; // 8 hours in milliseconds
  const expires = new Date(Date.now() + expirationTime);

  const cookieOptions: CookieOptions = {
    ...options,
    expires
  };

  Cookies.set(name, value, cookieOptions);
};

export const getCookie = (name: string): string | undefined => {
  return Cookies.get(name);
};

export const removeCookie = (name: string): void => {
  Cookies.remove(name);
};
