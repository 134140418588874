// axios.ts

import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { getCookie } from './cookie';


const instance: AxiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_URL}`, // Replace with your API base URL
  timeout: 50000 // Specify the request timeout
});

// Add a request interceptor to set common headers or handle tokens here
instance.interceptors.request.use(
  (config) => {
    // For example, adding authorization token
    const token: string | null = getCookie('AUTH_TOKEN');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle response errors globally
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with the response data
    return response;
  },
  (error: AxiosError) => {
    // Handle any error responses here
    return Promise.reject(error);
  }
);

export default instance;
