import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { AppContextProvider } from './appContext';
import MainLayout from './MainLayout';
import Toast from './components/Toast';
export function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <AppContextProvider>
          <MainLayout />
          <Toast />
        </AppContextProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}
