import { useContext } from 'react';

import { AppContext } from '../appContext';

export default function useAppContext() {
  const context = useContext(AppContext);
  return context;
}

export function useLoginInfo() {
  const context = useAppContext();
  const { loginInfo } = context;
  if (!loginInfo) return null;
  const { organization, permissions, practitioner, branch, setBranch } = loginInfo;
  if (!organization || !permissions || !practitioner) return null;
  return { organization, permissions, practitioner, branch, setBranch };
}
