import { Close } from '@mui/icons-material';
import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import CustomLoader from './CustomLoader';

interface Props {
  showDialog: boolean;
  onCloseDialog: () => void;
  title?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  className?: string;
  showCloseIcon?: boolean;
  children: ReactNode;
  isComboMenu?: boolean;
}

export default function DialogBox({
  showDialog,
  onCloseDialog,
  showCloseIcon,
  title,
  isLoading,
  width,
  height,
  children,
  className,
  isComboMenu
}: Props) {
  const BASE_STYLES =
    'fixed z-50 h-auto rounded !top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 bg-ilara-white min-w-[632px] tablet:!min-h-auto tablet:!h-auto tablet:!min-w-full tablet:!w-full tablet:bottom-0 tablet:right-auto tablet:!left-auto tablet:!top-auto tablet:translate-x-0 tablet:translate-y-0';

  const isCloseRender = () => {
    if (!showCloseIcon) return null;

    return (
      <Dialog.Close data-testid="close">
        <Close className="h-4 w-4 text-ilara-secondary-text" />
      </Dialog.Close>
    );
  };

  const isContentReady = () => {
    if (!isLoading) {
      return children;
    }
    return (
      <div data-testid="loading-dialog" className="flex flex-col mb-2 items-center">
        <CustomLoader type="circular" />
      </div>
    );
  };

  const getStyles = () => {
    const styles: React.CSSProperties = {};

    if (width) {
      styles.width = width;
    }

    if (height) {
      styles.height = height;
    }
    styles.top = 'unset';

    return styles;
  };

  return (
    <Dialog.Root data-testid="dialog-box" open={showDialog} onOpenChange={onCloseDialog}>
      {showDialog ? (
        <Dialog.Portal forceMount>
          <Dialog.Overlay
            forceMount
            className="h-screen w-full fixed inset-0 z-50 bg-ilara-black opacity-50"
          />
          <Dialog.Content
            forceMount
            style={getStyles()}
            className={clsx(BASE_STYLES, className && className)}
          >
            {!isComboMenu ? (
              <div className="sticky z-[1] top-0 bg-ilara-white flex px-6 py-3 justify-between items-center mobile:px-4">
                <Dialog.Title className="text-lg not-italic font-secondary-bold text-ilara-black">
                  {title}
                </Dialog.Title>
                {isCloseRender()}
              </div>
            ) : null}
            {isContentReady()}
          </Dialog.Content>
        </Dialog.Portal>
      ) : null}
    </Dialog.Root>
  );
}
