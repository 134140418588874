export function validateEmailAddress(email: string) {
  const pieces = email.split('@');
  // two pieces only
  if (pieces.length !== 2) return false;

  //both username and domain must exist and be string of length > 0
  const [username, domain] = pieces;
  if (!username || !domain) return false;

  // username must be between 1 and 64 characters
  if (username.length < 1 || username.length > 64) return false;

  // domain must be between 1 and 255 characters
  if (domain.length < 1 || domain.length > 255) return false;

  //username must be alphanumeric, have only hyphen, underscore or period and contain no spaces
  const usernameRegex = /^[a-zA-Z0-9-_.]+$/;
  if (!usernameRegex.test(username)) return false;

  // domain must be valid doman name or subdomain name
  const domainRegex = /^[a-zA-Z0-9-.]+$/;
  if (!domainRegex.test(domain)) return false;

  return true;
}
