import React, { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { AppBar, CardMedia, Toolbar, useMediaQuery } from '@mui/material';
import createTheme from '@mui/material/styles/createTheme';
import logo from '../assets/logo.svg';
import CustomAvatar from './Avatar';
import { useLocation } from 'wouter';
import CustomToolTipExtended from './CustomToolTipExtended';
import LogoutMenu from './LogoutMenu';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../helper/cookie';
import { getUserRole } from '../helper/getUserRole';
import { UserRoleEnum, userRoles } from '../tools/constants';

const drawerWidth = 260;

const theme = createTheme();
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  background: '#2A45CD',
  color: '#fff',
  overflowX: 'hidden',
  [theme.breakpoints.down(768)]: {
    width: '100%'
  }
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  background: '#2A45CD',
  color: '#fff',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down(768)]: {
    width: '100%'
  },
  [theme.breakpoints.between(768, 1024)]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    [theme.breakpoints.down(768)]: {
      position: 'relative',
      width: '100%'
    },
    [theme.breakpoints.between(768, 1024)]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
      flex: '0 0 0'
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const useStyles = makeStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    borderRadius: '4px',
    transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '8px 4px',
    height: '36px',
    '&:hover': {
      background: '#22379B',
      cursor: 'pointer',
      borderRadius: '4px 0 0 4px'
    },
    '&.active': {
      backgroundColor: '#23369C'
    },
    [theme.breakpoints.down(1024)]: {
      margin: '0 0 8px 16px'
    }
  },
  logoutContainer: {
    margin: '16px 0 16px 0',
    [theme.breakpoints.up(1425)]: {
      display: 'none'
    }
  },
  paper: {
    height: '80%'
  },
  navContainer: {
    margin: '16px'
  },
  closeNavContainer: {
    margin: '8px'
  }
});
//TODO: Settings link
// const settingsLinks = [
//   {
//     label: 'Settings',
//     icon: <SettingsOutlinedIcon />,
//     link: '/settings'
//   },
//   {
//     label: 'Support',
//     icon: <HelpOutlinedIcon />,
//     link: '/support'
//   },
//   {
//     label: 'Training center',
//     icon: <SchoolOutlinedIcon />,
//     link: '/training-center'
//   }
// ];

const LeftSidebar = () => {
  const [open, setOpen] = useState(false);
  const [searchBarVisible, setSearchBar] = useState<boolean>(false);
  const [location, setLocation] = useLocation();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const isMediumScreen = useMediaQuery('(max-width: 1023px)');

  // Decode the token using jwt-decode
  const decodedToken: any = getCookie('AUTH_TOKEN') ? jwtDecode(getCookie('AUTH_TOKEN')) : '';
  console.log('decodedToken', decodedToken);
  const sideBarLinks =
    decodedToken?.role === UserRoleEnum.ROLE_ADMIN
      ? [
          {
            label: 'Patients List',
            icon: <PeopleOutlineOutlinedIcon />,
            link: '/patientsList'
          },
          {
            label: 'Virtual Consultations List',
            icon: <ListIcon />,
            link: '/virtualConsultations'
          },
          {
            label: 'Users List',
            icon: <GroupIcon />,
            link: '/usersList'
          }
        ]
      : [
          {
            label: 'Patients List',
            icon: <PeopleOutlineOutlinedIcon />,
            link: '/patientsList'
          },
          {
            label: 'Virtual Consultations List',
            icon: <ListIcon />,
            link: '/virtualConsultations'
          }
        ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const handleSearch = () => {
    setSearchBar(true);
    setOpen(false);
  };

  const openLogoutMenu = (e: any) => {
    e.preventDefault();
  };

  const handleLogout = () => {
    // kcInstance.logout();
    // console.log('Logout clicked');
  };
  const listItemClick = (item: { label: string; link: string }) => {
    if (item === undefined) {
      return;
    }
    if (item) {
      setLocation(item.link);
    }
    isMobile && setOpen(false);
  };

  const companyName = () => {
    return (
      <p className="ml-1 font-secondary-bold text-lg overflow-hidden whitespace-nowrap uppercase text-ilara-white">
        ILARA HEALTH
      </p>
    );
  };

  return (
    <>
      <CssBaseline />
      {isMobile && !searchBarVisible && (
        <AppBar
          sx={{ background: '#2A45CD', color: '#fff', left: 0, width: '100vw', boxShadow: 'none' }}
        >
          <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="flex items-center">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: '36px',
                  [theme.breakpoints.down(768)]: {
                    marginRight: 0,
                    ...(open && { display: 'none' })
                  }
                }}
              >
                <MenuIcon />
              </IconButton>
              <CardMedia
                sx={{ width: 33 }}
                component="img"
                height={33}
                width={33}
                image={logo}
                alt="ilara logo"
              />
              <p className="ml-1 font-secondary-bold font-semibold text-lg overflow-hidden whitespace-nowrap uppercase text-ilara-white">
                ILARA HEALTH
              </p>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {!isMobile || (isMobile && open) ? (
        <Drawer variant="permanent" anchor="left" open={open}>
          <div
            className={`flex items-center justify-between ${
              open ? 'pl-5 pr-4' : 'pl-2 pr-2'
            } mb-9 pt-3`}
          >
            <div className="flex w-full items-center text-white justify-between">
              {!isMobile && (
                <IconButton
                  sx={{
                    color: '#fff',
                    '& .MuiButtonBase-root': {
                      marginRight: 0,
                      [theme.breakpoints.down(768)]: {
                        paddingTop: '20px'
                      }
                    }
                  }}
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                >
                  <MenuIcon />{' '}
                </IconButton>
              )}
              {open && !isMobile && (
                <div className="flex items-center">
                  <CardMedia
                    sx={{ width: 37 }}
                    component="img"
                    height={37}
                    width={38}
                    image={logo}
                    alt="ilara logo"
                  />
                  {companyName()}
                </div>
              )}
              {isMobile && companyName()}

              {isMobile && open && (
                <IconButton
                  sx={{
                    color: '#fff',
                    '& .MuiButtonBase-root': {
                      marginRight: 0,
                      [theme.breakpoints.down(768)]: {
                        paddingTop: '20px'
                      }
                    }
                  }}
                  onClick={handleDrawerClose}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div
            className={`flex items-center justify-between mb-4 ${
              open ? 'mr-4' : 'mr-0'
            } transition-transform duration-300 ease-in-out`}
          ></div>
          <div className="h-full relative overflow-hidden overflow">
            <div
              className={`pb-6 flex flex-col h-full transition-transform duration-300 ease-in-out ${
                open && isMediumScreen ? 'pl-0' : open ? 'pl-5' : 'pl-0'
              } ${open ? 'pr-5' : 'pr-0'}`}
            >
              {sideBarLinks
                // .filter((fLink) => hasRight(fLink.permission))
                .map((link) => {
                  const active =
                    location.startsWith(link.link) ||
                    (location.startsWith('/symptomDetails') &&
                      link?.link === '/virtualConsultations');
                  function activeClass(): string {
                    return active ? ' active' : '';
                  }
                  return (
                    <div
                      className={[
                        `flex items-center cursor-pointer ${classes.link}`,
                        activeClass()
                      ].join('')}
                      onClick={() => listItemClick(link)}
                      key={link.link}
                    >
                      <CustomToolTipExtended label={link.label}>
                        <IconButton
                          sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                            // alignItems: 'center',
                            dashboardcolor: '#FFFFFF',
                            letterSpacing: '-0.17px',
                            display: 'inline-block',
                            fill: '#fff',
                            color: '#fff',
                            flexShrink: 0,
                            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            fontFamily: 'var(--Secondary-Bold)',
                            padding: open ? '0 8px' : '0 16px',
                            '& .MuiSvgIcon-root': {
                              height: '20px',
                              width: '20px'
                            }
                          }}
                        >
                          {link.icon}
                        </IconButton>
                      </CustomToolTipExtended>
                      <p
                        className="ml-1 mt-2 flex items-center text-ilara-white font-secondary-bold font-bold text-sm overflow-hidden whitespace-nowrap"
                        key={link.label}
                      >
                        {link.label}
                      </p>
                    </div>
                  );
                })}
              <div className="flex cursor-pointer items-center justify-between m-4 lg:hidden">
                <LogoutMenu width="30%">
                  <div>
                    <CustomAvatar
                      show
                      onClick={(e) => openLogoutMenu(e)}
                      showIcon={true}
                      // prefix={loginInfo?.practitioner?.name.prefix}
                      // fullName={formatName(loginInfo?.practitioner?.name)}
                      fullName="Test User"
                      // description={loginInfo?.practitioner?.practitionerRole[0]?.roles[0]}
                    />
                  </div>
                </LogoutMenu>
                <IconButton onClick={handleLogout} size="large" color="inherit">
                  <PowerSettingsNewOutlinedIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </Drawer>
      ) : null}
    </>
  );
};

export default LeftSidebar;
