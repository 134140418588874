import clsx from 'clsx';
import React from 'react';

type Props = {
  title: string;
  className?: string;
};

export const MainTitle = ({ title, className }: Props) => {
  return (
    <p className={clsx(className, 'font-secondary-black text-ilara-black text-2xl leading-8')}>
      {title}
    </p>
  );
};

export const SubTitle = ({ title, className }: Props) => {
  return (
    <p className={clsx(className, 'font-secondary-bold text-ilara-black text-lg leading-6')}>
      {title}
    </p>
  );
};
