import React, { Fragment, forwardRef, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import clsx from 'clsx';
import { ComboboxOption, TagCategory } from '../types';
import { useMediaQuery } from '@mui/material';
import { mobile } from '../tools/designTokens';
import Chip from './Chip';
import DialogBox from './DialogBox';
import CustomLoader from './CustomLoader';

interface ComboboxProps {
  comboboxType: 'select' | 'search';
  options: ComboboxOption[];
  placeholder?: string;
  valueProperty: string;
  labelProperty: string;
  value?: ComboboxOption | ComboboxOption[];
  error?: string;
  label?: string;
  disabled?: boolean;
  name?: string;
  multiple?: boolean;
  isLoading?: boolean;
  filterOptions?: (query: string) => void;
  onSingleSelectChange?: (value: ComboboxOption | null) => void;
  onMultiSelectChange?: (value: ComboboxOption[] | null) => void;
  colorSelect?: boolean;
  inputClass?: string;
  hideSelectedOptionsChips?: boolean;
}

const CustomCombobox = forwardRef<HTMLSelectElement, ComboboxProps>(
  (
    {
      comboboxType,
      options,
      valueProperty,
      labelProperty,
      filterOptions,
      placeholder,
      label,
      disabled,
      name,
      error,
      value,
      multiple,
      onSingleSelectChange,
      onMultiSelectChange,
      colorSelect,
      inputClass,
      isLoading,
      hideSelectedOptionsChips
    },
    ref
  ) => {
    const [query, setQuery] = useState('');
    const [filteredOptions, setFilteredOptions] = useState<ComboboxOption[]>(options);
    useEffect(() => {
      setFilteredOptions(options);
    }, [options]);

    const handleFilterQuery = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setQuery(value);
      if (comboboxType === 'select') {
        const filtered =
          value === ''
            ? options
            : options.filter((option) => {
                return option?.[labelProperty]?.toLowerCase().includes(value.toLowerCase());
              });
        setFilteredOptions(filtered);
      } else {
        await filterOptions?.(e.target.value);
      }
    };
    const isMobile = useMediaQuery(`(max-width: ${mobile})`);
    const parsedOptions = JSON.parse(JSON.stringify(options));

    let selectedColor = null;

    parsedOptions?.forEach((option: TagCategory) => {
      if (option?.name === (value as ComboboxOption)?.[labelProperty]) {
        selectedColor = option.colour;
      }
    });

    if (isMobile)
      return (
        <Combobox
          value={value || ''}
          onChange={multiple ? onMultiSelectChange : onSingleSelectChange}
          disabled={disabled}
          name={name}
          by={valueProperty}
          ref={ref}
          nullable
          multiple={multiple as any}
        >
          <div className="space-y-2">
            {label ? (
              <Combobox.Label
                className={clsx(
                  'color-ilara-black block text-xs font-medium',
                  error && 'text-ilara-red'
                )}
                data-testid="combobox-label"
              >
                {label}
              </Combobox.Label>
            ) : null}
            {value?.length ? (
              <div className="flex flex-wrap">
                {!hideSelectedOptionsChips
                  ? Array.isArray(value)
                    ? value?.map((item: ComboboxOption, index: number) => (
                        <div key={index} className="pb-2 pl-2">
                          <Chip
                            result={item[labelProperty]}
                            onClick={() => {
                              const filteredOptions = value.filter(
                                (option) => option?.[valueProperty] !== item?.[valueProperty]
                              );
                              onMultiSelectChange?.(filteredOptions);
                            }}
                          />
                        </div>
                      ))
                    : null
                  : null}
              </div>
            ) : null}
            <div className="relative mt-1">
              <div className="w-full overflow-hidden text-left">
                <Combobox.Button className="w-full">
                  <input
                    className={clsx(
                      'w-full py-2 pl-3 pr-10 text-sm border rounded font-medium focus-visible:outline-none focus-visible:border-ilara-blue',
                      error && 'border-ilara-red',
                      disabled && 'text-ilara-label-placeholder',
                      colorSelect && 'pl-7'
                    )}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={handleFilterQuery}
                    value={!Array.isArray(value) ? value?.[labelProperty] || '' : ''}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 text-sm">
                    <KeyboardArrowDownIcon
                      style={{ color: disabled && 'var(--ilara-grey-mid)' }}
                      fontSize="medium"
                    />
                  </div>
                  {colorSelect ? (
                    <div className="absolute inset-y-0  left-2 flex items-center pr-2 text-sm">
                      <div
                        className="w-4 h-4 rounded-full"
                        style={{ backgroundColor: selectedColor }}
                      ></div>
                    </div>
                  ) : null}
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <div>
                  <DialogBox
                    isComboMenu
                    showDialog={true}
                    onCloseDialog={() => null}
                    className="pb-20"
                  >
                    <div className="px-4 py-4">
                      {label ? (
                        <Combobox.Label
                          className="block font-['Satoshi Bold'] text-sm pb-3 font-bold text-ilara-label-placeholder"
                          data-testid="combobox-label"
                        >
                          {label}
                        </Combobox.Label>
                      ) : null}

                      <Combobox.Input
                        data-testid="combobox-input"
                        type="select"
                        className={clsx(
                          'w-full py-2 pl-3 pr-10 text-sm border rounded font-medium focus-visible:outline-none focus-visible:border-ilara-blue',
                          error && 'border-ilara-red',
                          disabled && 'text-ilara-label-placeholder',
                          colorSelect && 'pl-7'
                        )}
                        placeholder={placeholder}
                        onChange={handleFilterQuery}
                        displayValue={() => (!Array.isArray(value) ? value?.[labelProperty] : '')}
                      />
                    </div>
                    <Combobox.Options className="max-h-60 w-full overflow-auto text-sm">
                      {isLoading ? (
                        <CustomLoader type="linear" />
                      ) : filteredOptions.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        filteredOptions?.map((option, index: number) => (
                          <Combobox.Option
                            key={option[valueProperty]}
                            className={({ active, selected }) =>
                              clsx(
                                'relative flex gap-2 cursor-default select-none py-2 px-4 border-b',
                                selected && 'bg-ilara-grey-mid',
                                active && 'bg-ilara-grey-light',
                                index === 0 && 'border-t'
                              )
                            }
                            value={option}
                          >
                            {({ selected }) => (
                              <>
                                {colorSelect ? (
                                  <div className="bg-inherit flex items-center gap-2">
                                    <div
                                      style={{ background: option.colour }}
                                      className="w-4 h-4 rounded-full"
                                    ></div>
                                  </div>
                                ) : null}
                                <div className="flex w-full justify-between">
                                  <span className="block truncate font-medium">
                                    {option[labelProperty]}
                                  </span>
                                  {selected ? (
                                    <span className="flex text-ilara-blue items-center justify-end pl-3">
                                      <DoneIcon fontSize="small" />
                                    </span>
                                  ) : null}
                                </div>
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </DialogBox>
                </div>
              </Transition>
            </div>
            {error ? (
              <p data-testid="combobox-error" className="text-ilara-red text-xs font-medium">
                {error}
              </p>
            ) : null}
          </div>
        </Combobox>
      );

    return (
      <Combobox
        value={value || ''}
        onChange={multiple ? onMultiSelectChange : onSingleSelectChange}
        disabled={disabled}
        name={name}
        ref={ref}
        by={valueProperty}
        multiple={multiple as any}
        nullable
      >
        <div className="space-y-2">
          {label ? (
            <Combobox.Label
              className={clsx(
                'color-ilara-black block text-xs font-medium',
                error && 'text-ilara-red'
              )}
              data-testid="combobox-label"
            >
              {label}
            </Combobox.Label>
          ) : null}
          {value?.length ? (
            <div className="flex flex-wrap">
              {!hideSelectedOptionsChips
                ? Array.isArray(value)
                  ? value?.map((item: ComboboxOption, index: number) => (
                      <div key={index} className="pb-2 pl-2" data-testid="combobox-chip">
                        <Chip
                          result={item[labelProperty]}
                          onClick={() => {
                            const filteredOptions = value.filter(
                              (option) => option?.[valueProperty] !== item?.[valueProperty]
                            );
                            onMultiSelectChange?.(filteredOptions);
                          }}
                        />
                      </div>
                    ))
                  : null
                : null}
            </div>
          ) : null}
          <div className="relative">
            <div className="w-full overflow-hidden text-left">
              <Combobox.Button className="w-full">
                <Combobox.Input
                  data-testid="combobox-input"
                  type="select"
                  className={clsx(
                    'w-full py-2 pl-3 pr-10 text-sm border rounded font-medium focus-visible:outline-none focus-visible:border-ilara-blue',
                    error && 'border-ilara-red',
                    disabled && 'text-ilara-label-placeholder',
                    colorSelect && 'pl-7',
                    inputClass
                  )}
                  placeholder={placeholder}
                  onChange={handleFilterQuery}
                  displayValue={() => (!Array.isArray(value) ? value?.[labelProperty] || '' : '')}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 text-sm">
                  <KeyboardArrowDownIcon
                    style={{ color: disabled && 'var(--ilara-grey-mid)' }}
                    fontSize="medium"
                  />
                </div>
                {colorSelect ? (
                  <div className="absolute inset-y-0  left-2 flex items-center pr-2 text-sm">
                    <div
                      className="w-4 h-4 rounded-full"
                      style={{ backgroundColor: selectedColor }}
                    ></div>
                  </div>
                ) : null}
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className={clsx(
                  'absolute mt-2 max-h-60 w-full overflow-auto rounded-md bg-white text-sm shadow-lg ring-1 ring-black ring-opacity-5 z-10',
                  colorSelect && '!mt-0'
                )}
              >
                {isLoading ? (
                  <CustomLoader type="linear" />
                ) : filteredOptions?.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map((option, index: number) => (
                    <Combobox.Option
                      data-testid="combobox-option"
                      key={index}
                      className={({ active, selected }) =>
                        clsx(
                          'relative flex gap-2 cursor-default select-none py-2 px-4',
                          selected && 'bg-ilara-grey-mid',
                          active && 'bg-ilara-grey-light',
                          index !== filteredOptions?.length - 1 && 'border-b'
                        )
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          {colorSelect ? (
                            <div key={index} className="bg-inherit flex items-center gap-2">
                              <div
                                style={{ background: option.colour }}
                                className="w-4 h-4 rounded-full"
                              ></div>
                            </div>
                          ) : null}
                          <div className="flex w-full justify-between">
                            <span className="block truncate font-medium">
                              {option[labelProperty]}
                            </span>
                            {selected ? (
                              <span className="flex text-ilara-blue items-center justify-end pl-3">
                                <DoneIcon fontSize="small" />
                              </span>
                            ) : null}
                          </div>
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
          {error ? (
            <p data-testid="combobox-error" className="text-ilara-red text-xs font-medium">
              {error}
            </p>
          ) : null}
        </div>
      </Combobox>
    );
  }
);

CustomCombobox.displayName = 'Combobox';

export default CustomCombobox;
