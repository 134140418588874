import React, { forwardRef } from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { SvgIconProps } from '@mui/material';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant: 'primary' | 'alternate' | 'primary-login' | 'danger';
  renderLeftIcon?: ({ fontSize }: { fontSize?: SvgIconProps['fontSize'] }) => JSX.Element;
  renderRightIcon?: ({ fontSize }: { fontSize?: SvgIconProps['fontSize'] }) => JSX.Element;
  disabled?: boolean;
  fullWidth?: boolean;
}

const generateClasses = (
  loading: boolean,
  disabled: boolean,
  variant: ButtonProps['variant'],
  fullWidth: boolean
) => {
  let baseStyles =
    'flex justify-center items-center rounded font-[800] text-xs tracking-[-0.13px] px-6 whitespace-nowrap smallMobile:w-full h-9';

  baseStyles = clsx(baseStyles, fullWidth ? 'w-full' : 'grow-0');

  const disabledOpacity = 'opacity-50';

  if (variant === 'primary' || variant === 'primary-login' || variant === 'danger') {
    const basePrimaryStyles = `py-1.5 text-ilara-white ${
      variant == 'primary-login'
        ? 'bg-ilara-blue-login'
        : variant === 'danger'
        ? 'bg-ilara-red'
        : 'bg-ilara-blue'
    }`;
    if (disabled) {
      return clsx(baseStyles, basePrimaryStyles, disabledOpacity);
    } else if (loading) {
      return clsx(baseStyles, basePrimaryStyles, 'border-[2px] border-solid border-[#647EFF]');
    }
    return clsx(
      baseStyles,
      basePrimaryStyles
      // 'hover:bg-ilara-darkest-blue active:bg-ilara-dark-blue'
    );
  } else {
    const baseAlternateStyles = 'py-[5px] text-ilara-black shadow-[0px_1px_0px_#00000029]';
    if (disabled) {
      return clsx(
        baseStyles,
        baseAlternateStyles,
        disabledOpacity,
        'border border-solid border-ilara-grey-mid'
      );
    } else if (loading) {
      return clsx(
        baseStyles,
        baseAlternateStyles,
        'bg-ilara-grey-white border-[2px] border-solid border-ilara-grey-mid'
      );
    }
    return clsx(
      baseStyles,
      baseAlternateStyles,
      'bg-ilara-white border border-solid border-ilara-grey-mid active:bg-ilara-button-select-grey active:shadow-[inset_0px_1px_0px_#00000017] hover:bg-ilara-button-select-grey hover:shadow-[0px_1px_0px_#00000024]'
    );
  }
};

const getLeftIcon = (renderLeftIcon: ButtonProps['renderLeftIcon']) => {
  if (!renderLeftIcon) return null;
  return (
    <div className="mr-2 text-xs" data-testid="left-icon">
      {renderLeftIcon({ fontSize: 'inherit' })}
    </div>
  );
};

const getRightIcon = (renderRightIcon: ButtonProps['renderRightIcon']) => {
  if (!renderRightIcon) return null;
  return (
    <div className="ml-2 text-xs" data-testid="right-icon">
      {renderRightIcon({ fontSize: 'inherit' })}
    </div>
  );
};

const TwindButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      loading = false,
      variant,
      disabled = false,
      children,
      renderRightIcon,
      renderLeftIcon,
      fullWidth = false,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const classes = generateClasses(loading, disabled, variant, fullWidth);
    return (
      <button
        {...props}
        ref={ref}
        className={classes}
        disabled={disabled || loading}
        data-testid="button"
        aria-disabled={disabled || loading}
      >
        {loading ? (
          <CircularProgress
            size={16}
            className="mr-2"
            data-testid="button-loading-spinner"
            sx={{ color: variant === 'primary' ? 'white' : 'var(--ilara-blue)' }}
            thickness={7}
          />
        ) : (
          getLeftIcon(renderLeftIcon)
        )}
        {children}
        {loading ? null : getRightIcon(renderRightIcon)}
      </button>
    );
  }
);

TwindButton.displayName = 'TwindButton';

export default TwindButton;
