import { ReactChild, ReactChildren } from 'react';

export interface OrganizationRolePermissions {
  superuser: boolean;
  'roles-edit': boolean;
  'roles-view': boolean;
  'triage-edit': boolean;
  'triage-view': boolean;
  'billing-edit': boolean;
  'billing-view': boolean;
  'patient-edit': boolean;
  'patient-view': boolean;
  'roles-create': boolean;
  'bio-data-edit': boolean;
  'expenses-edit'?: boolean;
  'expenses-create'?: boolean;
  'expenses-view'?: boolean;
  'expenses-archive'?: boolean;
  'expenses-summary-view'?: boolean;
  'messaging-broadcast-edit'?: boolean;
  'messaging-broadcast-view'?: boolean;
  'messaging-broadcast-create'?: boolean;
  'messaging-broadcast-archive'?: boolean;
  'roles-archive': boolean;
  'timeline-view': boolean;
  'triage-create': boolean;
  'billing-create': boolean;
  'diagnoses-edit': boolean;
  'diagnoses-view': boolean;
  'encounter-view': boolean;
  'inventory-edit': boolean;
  'inventory-view': boolean;
  'patient-create': boolean;
  'triage-archive': boolean;
  'billing-archive': boolean;
  'patient-archive': boolean;
  'diagnoses-create': boolean;
  'encounter-create': boolean;
  'examination-edit': boolean;
  'examination-view': boolean;
  'inventory-create': boolean;
  'patient-tag-edit': boolean;
  'patient-tag-view': boolean;
  'appointments-edit': boolean;
  'appointments-view': boolean;
  'diagnoses-archive': boolean;
  'encounter-archive': boolean;
  'inventory-archive': boolean;
  'organization-edit': boolean;
  'organization-view': boolean;
  'practitioner-edit': boolean;
  'practitioner-view': boolean;
  'examination-create': boolean;
  'patient-tag-create': boolean;
  'prescriptions-edit': boolean;
  'prescriptions-view': boolean;
  'appointments-create': boolean;
  'complaints-hpi-edit': boolean;
  'complaints-hpi-view': boolean;
  'examination-archive': boolean;
  'investigations-edit': boolean;
  'investigations-view': boolean;
  'organization-create': boolean;
  'patient-tag-archive': boolean;
  'practitioner-create': boolean;
  'admin-dashboard-view': boolean;
  'appointments-archive': boolean;
  'encounter-close-edit': boolean;
  'organization-archive': boolean;
  'practitioner-archive': boolean;
  'prescriptions-create': boolean;
  'save-visit-form-edit': boolean;
  'user-management-view': boolean;
  'complaints-hpi-create': boolean;
  'prescriptions-archive': boolean;
  'reports-external-view': boolean;
  'reports-internal-view': boolean;
  'complaints-hpi-archive': boolean;
  'review-of-systems-edit': boolean;
  'review-of-systems-view': boolean;
  'visit-information-edit': boolean;
  'visit-information-view': boolean;
  'clinic-information-edit': boolean;
  'clinic-information-view': boolean;
  'diagnoses-referral-edit': boolean;
  'investigations-lab-view': boolean;
  'medication-history-edit': boolean;
  'medication-history-view': boolean;
  'diagnoses-sick-note-edit': boolean;
  'review-of-systems-create': boolean;
  'inventory-stock-take-edit': boolean;
  'inventory-stock-take-view': boolean;
  'medication-history-create': boolean;
  'review-of-systems-archive': boolean;
  'investigations-result-edit': boolean;
  'medication-history-archive': boolean;
  'inventory-stock-take-create': boolean;
  'investigations-imaging-view': boolean;
  'investigations-request-edit': boolean;
  'inventory-stock-take-archive': boolean;
  'investigations-result-create': boolean;
  'patient-medical-history-edit': boolean;
  'patient-medical-history-view': boolean;
  'treatment-prescriptions-edit': boolean;
  'treatment-prescriptions-view': boolean;
  'inventory-stock-movement-edit': boolean;
  'inventory-stock-movement-view': boolean;
  'investigations-request-create': boolean;
  'investigations-result-archive': boolean;
  'investigations-request-archive': boolean;
  'patient-medical-history-create': boolean;
  'inventory-stock-movement-create': boolean;
  'patient-medical-history-archive': boolean;
  'inventory-stock-movement-archive': boolean;
}

export interface OrganizationRole {
  id: string;
  name: string;
  description?: null;
  organizationId: string;
  permissions: Permissions | OrganizationRolePermissions;
  createdAt: string;
  updatedAt: string;
}

export interface RolesAndPermissionsResponse {
  id: string;
  ksuid: string;
  createdAt: string;
  updatedAt: string;
  roles?: OrganizationRole[] | null;
}

export type POJO = { [key: string]: any };

export interface HumanName {
  prefix?: string | null;
  lastName: string;
  firstName: string;
  middleName?: string | null;
}

export interface Role {
  id: string;
  name: string;
  permissions: Record<string, boolean>;
  organizationId: string;
  description?: null | string;
  createdAt?: string;
  updatedAt?: string;
}

export type OrganizationAddress = {
  text: string;
  line?: string[] | null;
  city?: string;
  town?: string;
  county?: string;
  country?: string;
  geolocation?: null | string;
};

export type PatientAddress = Partial<{
  city: string | null;
  country: string | null;
  county: string | null;
  estate: string | null;
  geolocation: string | null;
  landmark: string | null;
  line: string[];
  residenceArea: string | null;
  subCounty: string | null;
  text: string | null;
  town: string | null;
  village: string | null;
}>;

export type Organization = {
  id: string;
  name: string;
  address: OrganizationAddress;
  active: boolean;
  payable: boolean;
  phoneNumbers?: string[];
  createdAt: string;
  updatedAt?: boolean | string;
  enrolledPrograms?: null[] | null;
  preferences?: Preferences;
  hasOdoo?: boolean;
  productOpsId?: null | string;
  productOpsEmail?: null | string;
  isTransformationSite?: boolean;
  monthlyVisitBaselineAtOnboarding?: number;
  logoLocation?: string;
  createdById?: null | string;
  lastUpdatedById?: string;
  createdBy?: CreatedBy | null;
  lastUpdatedBy?: CreatedBy;
};

export interface practitionerRole {
  id: string;
  links?: Record<string, unknown>;
  organizationId: string;
  practitionerId: string;
  roles: string[];
}

export type Practitioner = {
  id: string;
  birthDate: string;
  gender: 'MALE' | 'FEMALE';
  links?: POJO;
  name: HumanName;
  phoneNumber: string;

  practitionerRole?: practitionerRole[];
  email?: null | string;
  address?: null | string;
  practitionerContact?: null;
  createdAt?: string;
  updatedAt?: string;
  deactivatedAt?: null | string;
  deactivated: boolean;
};

export type Branch = {
  id: string;
  name: string;
};

export type PractitionerUser = {
  practitioner: Practitioner;
  organizationId: string;
  username: string;
  password: string;
  temporaryPassword: boolean;
  roles: string[];
};

export type PatientNextOfKin = {
  name: HumanName;
  phoneNumbers?: string[];
  contactRelationShip: string;
  gender?: null;
};

export type Patient = {
  name: HumanName;
  phoneNumber: string[] | null;
  active: boolean;
  address?: PatientAddress | null;
  autoGenerateOPNumber?: boolean | null;
  birthDate: string | null;
  emailAddress: string | null;
  enrolledPrograms?: string[] | null;
  externalId: string | null;
  externalSystemName?: string | null;
  gender: 'MALE' | 'FEMALE' | 'UNKNOWN';
  id: string;
  ipNumber: string | null;
  nearestHealthCtr: string | null;
  newPatient: boolean | null;
  nhifCapitationNumber?: string | null;
  firstEncounterDate?: string;
  lastEncounterDate?: string;
  nextAppointmentDate?: string | null;
  deceased?: boolean | null;
  deathDate?: null;
  isComplete?: boolean | null;
  branchId: string;
  createdById: string;
  lastUpdatedById: string;

  occupation: string | null;
  organizationId: string;
  preferences: string | null;
  studyId: string | null;
  createdAt: string | null | CreatedBy;
  updatedAt: string | null | CreatedBy;
  contact: PatientNextOfKin[] | null;

  createdBy: CreatedBy;
};

export type Appointment = {
  id: string;
  startDate: Date;
  endDate: Date;
  reason: string;
  type: string;
  patientId: string;
  practitionerId?: string;
  organizationId?: string;
  createdAt?: string;
  updatedAt?: string;
  status?: string;
  notes?: string;
  length?: number;
  objectId?: string;
  patient?: Patient;
  organization?: Organization;
  practitioner?: Practitioner;
  appointmentReturnReasonId?: string;
  branchId?: string | null;
  startAt?: null | Date;
};

export type Encounter = {
  id: string;
  visitTypeId: string;
  organizationId: string;
  practitionerId: string;
  patientId: string;
  patient: Patient;
  vitals: {
    id?: string;
    value: string;
    vitalTypeId: string;
    vitalsType?: VitalType;
  }[];
};

type VitalLabel =
  | 'Temperature'
  | 'Pulse'
  | 'Systolic BP'
  | 'Diastolic BP'
  | 'Respiratory rate'
  | 'Oxygen Levels'
  | 'Weight'
  | 'Height'
  | 'MUAC';

export type VitalType = {
  id: string;
  name: VitalLabel;
  description: string;
  type: 'NUMERIC' | 'TEXT' | 'DATE';
  unitOfMeasure: string;
  lowerBound: number | null;
  upperBound: number | null;
  example: string | null;
  index: number;
  active: boolean;
};

export type ICD10Code = {
  code: string;
  description: string;
  id: string;
  organizationId: string | null;
  quickList: boolean;
};

export type Medication = {
  name: string;
  lastTaken: string;
  reasonForMedication: string;
  patientEncounterId: string | null;
  practitionerId: string | null;
  id: string;
};

export type medicationHistory = {
  name: string;
  lastTaken: string;
  resonForMedication: string;
  id: string;
  patientEncounterId: string;
  practitionerId: string;
};

export type addHistory = {
  show?: boolean;
  onClose?: () => void;
  header?: string;
  action?: string;
  children?: ReactChild | ReactChildren;
};

export type ErrorType = {
  message?: string;
  statusCode?: number;
  errors?: any;
};

export type DataTableColumn = {
  id: string;
  label?: string;
  minWidth?: number;
  align?: 'inherit' | 'left' | 'right' | 'center' | 'justify';
  format?: (value: any, row?: DataTableRow) => any;
  icon?: () => ReactChild;
};

export type DataTableRow = {
  id: string;
  [key: string]: any;
};

export type tabs = {
  id: string;
  value: ReactChild | ReactChildren;
  label: string;
};

export type Vital = {
  temparature: number;
  pulse: number;
  respiratoryRate: number;
  systolic: number;
  diastolic: number;
  oxygenLevel: number;
  weight: number;
  height: number;
  muac: number;
  lmp: number;
};

export type DataTableAction = {
  label: string | any;
  onClick: (value: string) => void;
  data?: any;
};

export type CreatedBy = {
  fullName?: string;
  id?: string;
};

export type ComplaintFormTypes = {
  complaint: string;
  notes: string;
  duration: string;
  patientEncounterId?: string;
  id?: string;
  createdBy?: CreatedBy;
  lastUpdatedBy?: object;
  createdAt?: string;
};

export type VitalGroup = {
  vitals: Vital[];
  id: string;
  notes: string;
  createdAt: string;
  patientEncounterId: string;
};

export type AcrodionProp = {
  key: any;
  vitals?: VitalGroup;
};

export type VisitTypes = {
  id: string;
  name: string;
};

export type HpiType = {
  historyPresentingIllness: string;
};

export type Referral = {
  id?: string;
  patientEncounterId: string;
  clinicalSummary: string;
  investigationSummary: string;
  treatmentProvided: string;
  referralReason: string;
  createdById: string;
};

export type SickNote = {
  id?: string;
  patientEncounterId: string;
  excuseFrom: string;
  reason: string;
  validFrom: string;
  validTo: string;
  practitionerId: string;
};

export type BillingData = {
  patientEncounterId: string;
  amountPaid: number;
  totalCost: number;
  notes?: string;
  id?: string;
  createdAt?: string;
};

export interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
}

export interface PaymentMethod {
  id: string;
  name: string;
  organizationId: string;
  active: boolean;
  requireReferenceId?: boolean;
  paymentType?: null;
}

export type BillPayment = {
  id: string;
  billId: string;
  comment?: any;
  amount: number;
  paymentMethodId: string;
  paymentMethod?: PaymentMethod;
  referenceId?: any;
  paidById?: any;
  receivedById?: any;
  createdAt: Date | string;
};

export type Bill = {
  id: string;
  organizationId: string;
  externalId: string;
  amount: number;
  orignalAmount?: number;
  amountPaid: number;
  paymentStatus: string;
  billStatus: string;
  remainingAmount: number;
  billPayments: BillPayment[];
  createdAt: string;
  amountPayable?: number;
  priceChangeReason?: string;
};

export interface ProductCategory {
  product_category_uid: string;
  name: string;
}

export interface ActiveIngredient {
  active_ingredient_uid: string;
  name: string;
}

export interface ProductPackage {
  product_package_uid: string;
  name: string;
  amount: number;
  price: number;
  is_base_package: boolean;
  is_for_sale: boolean;
  sku?: string | null;
}

export type Product = {
  product_uid: string;
  client_uid: string;
  name: string;
  type: ProductType;
  sku?: string;
  product_category: ProductCategory;
  active_ingredients: ActiveIngredient[];
  product_packages: ProductPackage[];
  created_at: string;
  low_stock_level_limit: number | null;
};

export interface Inventory {
  inventory_uid: string;
  branch_uid: string | null;
  quantity: number;
  product: Product;
}

export type Order = {
  product_package_uid: string;
  name: string;
  amount: number;
  price: number;
  is_base_package: boolean;
  is_for_sale: boolean;
  sku: string;
  product: Product;
  quantity: number;
};

export enum ProductType {
  product = 'product',
  service = 'service'
}

export interface PaginationMeta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string | null;
  previous_page_url: string | null;
}

export interface StockMovementType {
  stock_movement_type_uid: string;
  name: string;
}

export interface StockMovement {
  stock_movement_uid: string;
  direction: string;
  quantity: number;
  opening: number;
  closing: number;
  stock_movement_type: StockMovementType;
  product_package: ProductPackage & { product: Product };
  created_at?: string;
}

export interface PatientEncounterInfo {
  id: string;
  visitType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: string;
  ipNumber?: string;
  phoneNumber?: string;
}

export interface ExpenseHistory {
  id: string;
  description: string;
  category: string;
  amount: number;
  incurredBy: string;
  date: Date;
}

export interface Expense {
  id: string;
  reporter: string;
  amount: number;
  active: boolean;
  expenseNameId: string;
  organizationId: string;
  expenseName: ExpenseName;
  createdAt: Date;
  incurredDate: Date;
  digitalReceipts: any;
}

export interface ExpenseName {
  id: string;
  name: string;
  organizationId: string;
  expenseCategoryId: string;
  expenseCategory: ExpenseCategory;
  createdAt: Date;
}

export interface ExpenseCategory {
  id: string;
  name: string;
  description: string | null;
  createdAt: Date;
}

export interface ExpenseSummaryItem {
  categoryName: string;
  amount: number;
}

export interface StaffData {
  userId: string;
  practitioner: StaffPractitioner;
  username: string;
  userRole: string;
  fullName: string;
  gender: string;
  age: number;
  fullDetails: string;
}

export interface StaffPractitioner {
  id: string;
  name: HumanName;
  gender: string;
  phoneNumber: string;
  birthDate: string;
  practitionerRole?: StaffPractitionerRoleEntity[] | null;
  email: string;
  address: string;
  practitionerContact?: null;
  createdAt: string;
  updatedAt: string;
  deactivatedAt?: string | null;
  deactivated: boolean;
}

export interface StaffPractitionerRoleEntity {
  id: string;
  practitionerId: string;
  organizationId: string;
  roles?: string[] | null;
}

export interface ResponseDataStaff {
  data?: StaffData[] | null;
  totalElements: number;
  totalPages: number;
  last: boolean;
  first: boolean;
  sort: Sort;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface DataForDate {
  date: string | Date;
  amount: number;
  apiKey: string;
}

export interface DataForMonth {
  month: string | Date;
  count: number;
  apiKey: string;
}

export type Data = DataForMonth | DataForDate;

export interface FilterStats {
  startDate?: null | Date;
  endDate?: null | Date;
  filters?: ItemData[] | null;
  branchId?: FilterStatsBranch | null;
  length?: string | number;
}

export interface FilterStatsBranch {
  id?: string;
  name?: string;
}

export interface ObjectsData {
  id?: BranchData;
  startDate?: string | Date;
  endDate?: string | Date;
}

export interface ItemData {
  startDate?: Date | null;
  duration?: string;
  endDate?: Date | null;
  branchId?: BranchData | null;
  name?: string;
  length?: string | number;
}

export interface BranchData {
  branchId?: string;
  name?: string;
  id?: string;
  phoneNumber?: string;
}

export interface ResponseData {
  data: string;
  meta: {
    filename: string;
  };
}

export interface MohReportTypes {
  organizationId?: string;
  startDate?: string;
  endDate?: string;
  underFive?: boolean;
  overFive?: boolean;
}

export interface Report {
  name?: string;
  des?: string;

  code: string;
  url: string;
  newApi: boolean;
  template?: TemplateEntity[] | null;
}

export interface TemplateEntity {
  description: string;
}

export interface BackendSMSResponse {
  data: {
    data: SmsData;
  };
}

export interface SmsData {
  totalPages?: number;
  size?: number;
  totalItems?: number;
  status?: string;
  sendAfter?: string;
  items?: MessageItems[] | null;
  label?: string;
  template?: string;
}

export interface MessageItems {
  id: string;
  createdAt: string;
  updatedAt: string;
  sendAfter: string;
  sendFrom: string;
  label: string;
  template: string;
  organizationId: string;
  status: string;
  meta?: null;
  recipientsCount: number;
  costCredits: number;
  deliveredPercentage: number;
  failedPercentage: number;
  pendingPercentage: number;
}

export type StockTakeDetails = {
  data: {
    data: {
      stock_take_uid: string;
      stock_movement_uid: string;
      inventory_uid: string;
      mode: 'partial' | 'full';
      quantity: number;
    };
    message: string;
  };
};

export type Batch = {
  id: number;
  inventory_uid: string;
  batch_uid: string;
  batch_no: string;
  quantity: number;
  expiry_date: string;
  unitsCollected?: number;
  product_package_uid: string;
  created_at: string;
  updated_at: string;
};

export type BranchDetails = {
  id: string;
  name: string;
  organizationId: string;
  mflCode: string;
  phoneNumber: string;
  email: string;
  active: boolean;
  createdById: string | null;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  createdBy: null;
  lastUpdatedBy: {
    id: string;
    fullName: string;
  };
};

export interface ErrorHandle {
  message?: string;
  statusCode?: number;
  errors: Errors;
}

export interface Errors {
  message?: string;
}

export interface IReceipt {
  id?: string;
  branchId?: string;
  organizationId?: string;
  headerInformation?: string;
  footerInformation?: string;
  receiptType: 'THERMAL' | 'REGULAR' | string;
  showLogo: boolean;
  createdById?: string;
  createdAt?: string;
  lastUpdatedById?: string;
  updatedAt?: string;
}

interface ITmpData {
  data: IReceipt;
}

export interface IReceiptOptionsPostResponse {
  data: ITmpData;
}

export interface IReceiptOptionsResponse {
  data: IReceipt[];
}

export interface IProductPackage {
  product_package_uid: string;
  name: string;
  amount: number;
  price: number;
  is_base_package: boolean;
  is_for_sale: boolean;
  archived_at: null | string;
  product: {
    product_uid: string;
    client_uid: string;
    name: string;
    created_at: string;
    type: string;
    sku: null | string;
    archived_at: null | string;
  };
  quantity: number;
}

export interface IUpdatedBill {
  id: string;
  orderNumber: string;
  organizationId: string;
  branchId: string;
  externalId: string;
  customerId: null | string;
  amount: number;
  amountPaid: number;
  paymentStatus: 'PARTIALLY_PAID' | 'Unpaid';
  billStatus: 'CLOSED';
  billType: 'ENCOUNTER';
  remainingAmount: number;
  billPayments: BillPayment[];
  patientEncounterInfo: PatientEncounterInfo;
  createdAt: string;
}

export interface IUpdatedPatientEncounterInfo {
  id: string;
  visitType: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: 'FEMALE';
  ipNumber: string;
  phoneNumber: string;
  birthDate: string;
}

export interface ILogoFileData {
  id: string;
  originalFileName: string;
  fileObjectName: string;
  extension: string;
  uploadUrl: string;
  practitionerId: string;
  organizationId: string;
  uploadStatus: string;
  uploadedAt: null | string;
}

export interface IInvoiceRecipient {
  id: string;
  invoiceOptionId: string;
  name: string;
  address: string;
  active: boolean;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
}

export enum PaymentStatus {
  partial = 'PARTIALLY_PAID',
  paid = 'PAID',
  unpaid = 'NOT_PAID'
}
export enum HistoryTypes {
  allergies,
  medicalSurgicalHistory,
  familyHistory
}

export type BranchDetailsRes = {
  data: BranchDetails[];
};

export interface Preferences {
  id: string;
  organizationId: string;
  preferredLanguage: string;
}

export interface AppointmentLabels {
  result: string;
  data?: DataEntityLabels[] | null;
}

export interface DataEntityLabels {
  id: string;
  label: string;
}

export type PregnancyOptions = {
  id: string;
  formChoiceId: string;
  label: string;
  name?: string;
};

export interface OptionsNameAndList {
  id?: string;
  structuredVisitFormId?: string;
  name: string;
  choiceType?: string;
  optionsList?: PregnancyOptions[];
}

interface LastUpdatedBy {
  id: string;
  fullName: string;
}

export type ANCEncounter = {
  id?: string;
  patientEncounterId?: string;
  pregnancyRegistrationId?: string;
  visitNumber?: null | string | number;
  currentEga?: null | string | number;
  dangerSignEvaluation?: null | (string | string[])[];
  dangerSignEvaluationComments?: string;
  foetalMovement?: null | (string | string[]);
  foetalMovementComments?: string;
  breastExam?: null | (string | string[] | number);
  breastExamComments?: string;
  fundalHeight?: null | (number | string);
  presentation?: null | (number | string);
  lie?: null | (number | string);
  foetalHeartDetected?: null | boolean;
  foetalHeartRate?: null | number;
  foetalHeartComments?: string;
  complicationsDetected?: boolean;
  currentVisitComplications?: string[];
  complicationComments?: string;
  haemoglobin?: null | number;
  bloodGroupingDone?: null | number;
  bloodGroup?: null | number;
  rhesus?: null | number;
  urinalysisDone?: null | number;
  bloodSugar?: null | number;
  bloodSugarResultClassification?: null | number;
  coupleHivCounselingAndTestingDone?: boolean;
  partnerHivStatus?: null | (number | string);
  hepatitisB?: null | number;
  syphilisTestDone?: boolean;
  syphilisTestResult?: null | number;
  syphilisTreatmentDone?: null | boolean;
  tbScreening?: string;
  llitnProvided?: boolean;
  investigationComments?: string;
  dewormingDone?: boolean;
  tdVaccinationDone?: null | boolean;
  malariaMedicationDone?: null | boolean;
  preventiveServicesComments?: null | string;
  ifasGiven?: boolean;
  calciumGiven?: boolean;
  supplementationComments?: string;
  whoStage?: string;
  artComments?: string;
  viralLoad?: number;
  onArvBeforeFirstAncVisit?: boolean;
  startedOnHaartThisVisit?: string;
  artRegimen?: null | number;
  cotrimoxazoleProvided?: string;
  prophylaxisComments?: string;
  referralTo?: string;
  reasonForReferral?: (string | string[])[];
  referralComments?: string;
  nextAppointment?: null | string;
  createdById?: string;
  createdAt?: string;
  lastUpdatedById?: string;
  updatedAt?: string;
  createdBy?: CreatedBy;
  lastUpdatedBy?: LastUpdatedBy;
};

export interface PregnancySummary {
  id: string;
  patientEncounterId: string;
  ancVisits: number;
  gestationInWeeks: number;
  pregnancyOutcome: string;
  modeOfDelivery: string;
  placeOfDelivery: string;
  sex: string;
  birthYear: string;
  birthWeightInGrams: number;
  createdById?: string;
  createdAt?: string;
  lastUpdatedById?: string;
  updatedAt?: string;
  createdBy?: CreatedBy;
  lastUpdatedBy?: LastUpdatedBy;
}

export interface PregnancyRegistrationForm {
  id?: string;
  patientEncounterId?: string;
  active?: null | boolean;
  referredIn: null | string | any;
  referredFrom: null | string;
  referralReason: null | string;
  weight: null | string;
  height: null | string;
  maritalStatus: null | string;
  usedFamilyPlanning: null | boolean;
  mostRecentFpMethod: null | string;
  usedFpMethods: null | string[];
  pregnanciesOverSixMonths: null | string;
  pregnancyTerminationsUnderSixMonths: null | string;
  gravida: null | string | number;
  lmp: null | string | Date;
  edd: null | string | Date;
  gestationInWeeks: null | string | number;
  undergoneFgm: null | boolean;
  fgmComplication: null | string;
  hivStatus: null | string;
  createdById?: string;
  createdAt?: string;
  lastUpdatedById?: string;
  updatedAt?: string;
  createdBy?: CreatedBy;
  lastUpdatedBy?: LastUpdatedBy;
}

export interface PregnancyTermination {
  id?: string;
  patientEncounterId?: string;
  pregnancyRegistrationId?: string | boolean;
  pregnancyOutcome: null | string | boolean;
  gestationInWeeksAtPregnancyTermination: null | number | string;
  modeOfDelivery: null | string | boolean;
  placeOfDelivery: null | string | boolean;
  genderOfBaby: null | string | boolean;
  birthWeight: null | number;
  createdById?: string;
  createdAt?: string;
  lastUpdatedById?: string;
  updatedAt?: string;
  createdBy?: CreatedBy;
  lastUpdatedBy?: LastUpdatedBy;
}

export interface PreviousPregnancyForm {
  ancVisits: number;
  gestationInWeeks: number;
  pregnancyOutcome: string;
  modeOfDelivery: string;
  placeOfDelivery: string;
  sex: string;
  birthWeightInGrams: number;
  birthYear: Date;
}

export interface FormOptions {
  id: string;
  formChoiceId: string;
  label: string;
  index: number;
}

export type PNCFormResponse = {
  id: string;
  structuredVisitFormId: string;
  name: string;
  choiceType: string;
  optionsList: FormOptions[];
};

export type PNCData = Examination & PNC & FamilyPlanning & Investigation & MaternalHealth;

export type PNCResponse = {
  data: PNCData[];
};
export interface Examination {
  breasts: string;
  cesareanSectionScar: string;
  involutionOfUterus: string;
  pelvicExam: string;
  conditionOfEpisiotomy: string;
  examinationComments: string;
  colour: null | string;
  amount?: null | string;
  smell?: null | string;
}

export interface PNC {
  id?: string;
  visit: null | string;
  generalCondition: null | string;
  updatedAt?: string;
  lastUpdatedBy?: LastUpdatedBy;
}

export interface FamilyPlanning {
  fpCounseling?: null | string;
  fpMethod: string[];
  fpComments: null | string;
}

export interface Investigation {
  motherOnHaart: null | string;
  lochiaComments: string;
}

export interface MaternalHealth {
  mentalHealthComments: string;
  postPartumDepressionIndicators: boolean | null;
}

interface LastUpdatedBy {
  id: string;
  fullName: string;
}

export interface PregnancyRegistrationResponse {
  data?: PregnancyRegistrationForm[];
}

export type responseDataPatient = {
  data: {
    data: {
      id: string;
    };
  };
};

interface InvoiceData {
  id: string;
  branchId: string;
  organizationId: string;
  footerInformation: string;
  showLogo: boolean;
  invoiceRecipients: InvoiceRecipient[];
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
}

interface InvoiceRecipient {
  id: string;
  invoiceOptionId: string;
  name: string;
  address: string;
  active: boolean;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
}

export interface IInvoiceResponse {
  pageable: Pageable;
  data: InvoiceData[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  first: boolean;
  sort: Sort;
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface Permission {
  name?: string;
  view?: boolean;
  edit?: boolean;
  create?: boolean;
  archive?: boolean;
}
interface IRecipientAddress {
  name: string;
  address: string;
}

export interface IInvoiceRecipientAtom {
  dueDate: Date | null;
  recipientAddress: IRecipientAddress | null;
}

export interface IDateRangeFilter {
  key: string;
  displayValue: string;
  value: string;
}

interface BaseDataType {
  id: string;
  patientEncounterId: string;
  comments: string;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  createdBy: {
    id: string;
    fullName: string;
  };
  lastUpdatedBy: {
    id: string;
    fullName: string;
  };
}
export interface AllergyData extends BaseDataType {
  substance: string;
  allergenClassId: string | null;
  allergenClass: string | null;
  onset: string;
  allergyReactionTypeId: string;
  allergyReactionType: {
    id: string;
    name: string;
  };
  allergyReactionSeverityTypeId: string;
  allergyReactionSeverityType: {
    id: string;
    name: string;
  };
}
export interface MedicalSurgicalDataType extends BaseDataType {
  name: string;
  datePerformed: string;
}
export interface FamilyHistoryDataType extends BaseDataType {
  name: string;
  relationship: string;
  dateDiagnosed: string | null;
}

export type combinedHistoryTypes = AllergyData | MedicalSurgicalDataType | FamilyHistoryDataType;

export interface patientEncounterData {
  result: string;
  data: EncounterData;
}

export interface VitalGroups {
  id: string;
  patientEncounterId: string;
  notes: string;
  vitals: any[];
  triageStatusId: string;
  triageStatus: TriageStatus;
  capturedById: string;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  createdBy: CreatedBy;
  lastUpdatedBy: LastUpdatedBy;
}
export interface PatientDataEncounter {
  id: string;
  name: HumanName;
  phoneNumber: string[];
  organizationId: string;
  address: Address;
  gender: string;
  birthDate: string;
  active: boolean;
  nhifCapitationNumber: string;
  nearestHealthCtr: any;
  studyId: any;
  emailAddress: string;
  externalId: string;
  externalSystemName: any;
  contact: Contact[];
  enrolledPrograms: any[];
  preferences: any;
  lastTouched: string;
  organization: Organization;
  ipNumber: string;
  occupation: string;
  newPatient: boolean;
  autoGenerateOPNumber: any;
  howPatientHeardAboutFacility: string;
  firstEncounterDate: string;
  lastEncounterDate: string;
  nextAppointmentDate: any;
  deceased: boolean;
  deathDate: any;
  isComplete: any;
  branchId: string;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  createdBy: CreatedBy;
  lastUpdatedBy: LastUpdatedBy;
}

export interface EncounterData {
  id: string;
  patientId: string;
  organizationId: string;
  practitionerId: string;
  status: string;
  active: boolean;
  priority: number;
  externalId: any;
  startAt: string;
  endAt: any;
  chiefComplaints: any;
  consultationNotes: any;
  investigationRequest: any;
  investigationResult: any;
  treatment: any;
  sourceAppointmentId: any;
  followupAppointmentId: any;
  followUpAppointment: any;
  visitTypeId: string;
  diagnosisCodeList: any[];
  visitType: VisitType;
  vitals: any[];
  patient: PatientDataEncounter;
  organization: Organization;
  practitioner: Practitioner;
  investigationRequestTypeId: any;
  investigationRequestType: any;
  seenById: any;
  seenBy: any;
  billing: any;
  fieldList: any[];
  programId: any;
  visualAcuityRightEye: any;
  visualAcuityLeftEye: any;
  referredFrom: string;
  referredTo: any;
  tbScreening: any;
  malaria: any;
  lastMenstrualPeriod: any;
  familyPlanningMethod: any;
  followUpSmsSchedule: any[];
  followUpAppointmentTs: any;
  historyPresentingIllness: any;
  managementPlan: any;
  isReviewEncounter: boolean;
  vitalGroups: VitalGroups[];
  nutritionalAdvice: any;
  branchId: any;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  createdBy: CreatedBy;
  lastUpdatedBy: LastUpdatedBy;
}

export interface VisitType {
  id: string;
  name: string;
}

export interface Address {
  county: string;
  subCounty: string;
  residenceArea: string;
}

export interface Contact {
  name: HumanName;
  contactRelationship: string;
  phoneNumbers: string[];
  gender: any;
}

export interface TriageStatus {
  id: string;
  name: string;
  colour: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ICartItem {
  id?: string;
  createdAt?: string;
  active?: boolean;
  product_package_uid?: string;
  name: string;
  amount: number;
  sellingPrice?: number;
  billItem?: BillItem;
  price: number;
  is_base_package?: boolean;
  is_for_sale?: boolean;
  archived_at?: string | null;
  billId?: null | string;
  externalId?: null | string;
  quantity?: null | number;
  itemName?: string;
  priceChanged?: number | boolean;
  priceLastChangedAt: null | number;
  productName?: string;
  totalSellingPrice?: number;
  unitPrice?: number;
}

export interface IBillItem {
  name: string;
  quantity: number;
  price: number;
  id: string;
  billId: string;
  externalId: string;
  itemName: string;
  productName: string;
  unitPrice: number;
  amount: number;
  active: boolean;
  createdAt: string;
}

export interface IPatientEncounterResponse {
  data: patientEncounterData;
  isLoading: boolean;
  isSuccess: boolean;
}

export interface ICSnackbar {
  isOpen: boolean;
  message: string;
  severity: 'error' | 'success' | 'warning' | 'info';
  alertTitle: string;
}

export interface ISelectedCartItem {
  openBill: IUpdatedBill[];
  name: string;
  productPackage: ProductPackage;
}

export enum ToastVariant {
  error,
  info,
  success
}

export type ToastData = {
  variant: ToastVariant;
  open: boolean;
  title: string;
  description?: string;
};

export type BillingOrderResponse = {
  data: BillingOrder[];
};

export type BillingOrder = {
  id: string;
  orderNumber: string;
  organizationId: string;
  branchId: string;
  externalId: string;
  customerId: string;
  amount: number | null;
  amountPaid: number | null;
  paymentStatus: 'NOT_PAID' | 'PARTIALLY_PAID' | 'FULLY_PAID';
  billStatus: string;
  billType: string;
  remainingAmount: number;
  billPayments: any[];
  patientEncounterInfo: {
    id: string;
    visitType: string;
    firstName: string;
    middleName: string;
    lastName: string;
    gender: 'MALE' | 'FEMALE';
    ipNumber: string;
    phoneNumber: string;
    birthDate: string; // Update the actual type for birthDate if needed
  };
  createdAt: string;
};

export type PdfDataPrescription = {
  id: string;
  name: string;
  dose: string;
  prescriptionFormulationId: string;
  prescriptionFormulation: any;
  prescriptionFrequencyId: string;
  prescriptionFrequency: any;
  duration: string;
  code: string;
  prescriptionAdministrationRouteId: string;
  prescriptionAdministrationRoute: any;
  additionalInstructions: string;
  patientEncounterId: string;
  createdAt: string | Date;
  updatedAt: string | Date;
  createdById: string;
  createdBy: CreatedBy;
  lastUpdatedById: string;
  lastUpdatedBy: CreatedBy;
};

export type PdfDataInvestigationParameterResultList = {
  id: string;
  parameter: any;
  value: string;
  flag: string;
  parameterId?: string;
};

export type PdfDataInvestigation = {
  investigationRequest: any;
  investigationRequestId?: string;
  id: string;
  parameterResultList: PdfDataInvestigationParameterResultList[];
  notes: string;
  value: string;
  createdAt: Date;
};

export type PdfTriage = {
  dateTime: string | Date;
  triageStatus?: string;
  temperature: string;
  pulse: string;
  bloodPressure: string;
  respiratoryRate: string;
  bloodOxygenation: string;
  lmp: string;
  weight: string;
  height: string;
  muac: string;
  systolic: string;
  diastolic: string;
};

export type PdfDiagnoses = {
  code: string;
  description: string;
  type: string;
};

export type Diagnosis = {
  id: string;
  code: string;
  cmCode: string;
  description: string;
  quickList: boolean;
  version: string;
  chapter: string;
};

export interface RowData {
  userId: string;
  practitioner: Practitioner;
  username: string;
  userRole: string;
}

export interface StaffManagementData {
  pageable: Pageable;
  data: RowData[];
  sort: Sort;
  totalPages: number;
  last: boolean;
  totalElements: number;
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
  empty: boolean;
}

export type PdfDataComplaint = {
  duration: string;
  complaint: string;
  notes: string;
};

export type EncounterSummaryPdfResponse = {
  id: string;
  prescriptionList: PdfDataPrescription[];
  complaintList: PdfDataComplaint[];
  investigationResultList: PdfDataInvestigation[];
  investigationRequestList?: PdfDataInvestigation[];
  vitalGroupReportDtoList: PdfTriage[];
  diagnoses: PdfDiagnoses[];
  treatment: string;
  facilityName: string;
  facilityAddress: string;
  facilityPhoneNumber: string;
  practitionerName: string;
  externalId: string;
  dateOfVisit: string | Date;
  visitType: string;
  lastEncounterDate: string | Date;
  nextAppointmentDate: string | Date;
  patientName: string;
  dateOfBirth: string | Date;
  gender: string;
  age: string;
};

export interface LaboratoryData {
  id: string;
  investigationId?: string;
  investigationListId: string;
  statusId: string;
  investigationList: InvestigationList;
  status: ResultFormat;
  notes: string;
  sampleCode?: null;
  parameterList?: Parameter[];
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  resultFormat?: ResultFormat;
  name?: string;
  createdBy: CreatedBy;
  lastUpdatedBy: CreatedBy;
}

export interface InvestigationList {
  id: string;
  name: string;
  description: string;
  loincCode?: string | null;
  testFunctionId: string;
  resultFormatId: string;
  otherNames?: string | null;
  specimenTypeId?: string | null;
  sampleContainerTypeId?: string | null;
  parameterList?: [] | Parameter[];
  testFunction: ResultFormat;
  resultFormat: ResultFormat;
  specimenType?: ResultFormat | null;
  sampleContainerType?: ResultFormat | null;
  investigationRequestList: LaboratoryData[];
}

export interface Department {
  id: string;
  name: string;
  investigationCategoryId: string;
  investigationCategory: ResultFormat;
}

export interface ResultFormat {
  id?: string;
  name?: string;
  description?: string;
  departmentId?: string;
  department?: Department;
}
export type DigitalReceipt = {
  originalFileName: string;
  fileObjectName: string;
  extension: string;
  uploadUrl: string;
  expenseId: null;
  uploadStatus: string;
  createdById: null;
  createdAt: null;
  lastUpdatedById: null;
  updatedAt: null;
};

export type APIResponse<T> = {
  data: T;
};

export type BulkMessagesResponse = {
  data?: {
    items: MessageItems[];
    totalItems: number;
    totalPages: number;
    page: number;
    size: number;
    sortField: string;
    sortOrder: string;
  };
};

export type SyncOrganizationResponse = {
  data?: {
    id: string;
    entityId: string;
    credits: number;
    createdAt: string;
    updatedAt: string;
  };
};

export type InvestigationRequestList = {
  id: string;
  investigationId?: string;
  investigationListId: string;
  value?: null | string;
  investigationRequestId?: string;
  parameterResultList?: LabImagingData[] | null;
  digitalImagingUploadList?: string[] | null;
  investigationList?: LaboratoryData;
  statusId: string;
  status?: ResultFormat;
  notes?: string;
  sampleCode?: null;
  createdById: string;
  createdAt: string;
  lastUpdatedById: string;
  updatedAt: string;
  createdBy: CreatedBy | null;
  lastUpdatedBy: CreatedBy;
};

export interface LabImagingData {
  id: string;
  parameterId: string;
  value: string;
  appliedNormalValueMax: number;
  appliedNormalValueMin: number;
  parameter: Parameter;
  flag: string;
}

export interface Parameter {
  id: string;
  name: string;
  description: string;
  loincCode: string;
  unitId: string;
  resultTypeId: string;
  minimum: number;
  maximum: number;
  normalResult?: null;
  comment?: null;
  index: number;
  otherResultFlag?: null;
  unit: ResultFormat;
  resultType: ResultFormat;
  options?: string | string[];
}

export type ComboboxOption = {
  [Key: string]: string;
};

export type BillItem = {
  id: string;
  billId: string;
  externalId: string;
  itemName: string;
  productName: string;
  quantity: number;
  name?: string;
  price?: number;
  unitPrice: number;
  sellingPrice: number;
  amount: number;
  totalSellingPrice: number;
  active: boolean;
  priceChanged: boolean;
  priceLastChangedAt: string;
  createdAt: string;
};

export type OTCRes = {
  data: OtcDara;
};

export type OtcDara = {
  data: Patient;
  result?: string;
};

export type OTCPatient = {
  name: HumanName;
  phoneNumber: string[];
  organizationId: string;
  gender: string;
  birthDate: string | Date;
  isComplete: boolean;
};

export interface UseMutationPostRequest {
  error: null;
  failureCount: number;
  isPaused: boolean;
  status: 'idle' | 'loading' | 'success' | 'error';
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isIdle: boolean;
  reset: () => any;
  data: any;
  mutate: (payload: any) => any;
}

export type UpdateAppointmentStatus = {
  id: string;
  status: string;
};

export type TableColumn = SingleRowColumn | MultiRowColumn;

export type SingleRowColumn = {
  id: number;
  multiRow: false;
  columnName: string;
  columnKey: string;
  sortOrder: 'asc' | 'desc';
};

export type MultiRowColumn = {
  id: number;
  multiRow: true;
  columnName: string;
  primaryRowKey: string;
  secondaryRowKey: string;
  sortOrder: 'asc' | 'desc';
};

export type TableData = { [key: string]: string | number | boolean }[];

export type UpdateCell = {
  [key: string]: {
    edited: boolean;
    updatedValue: string | number;
  };
};

export type UpdateRow = UpdateCell[];

export interface TBCareForm {
  id?: string;
  patientEncounterId: string;
  nationalId: number;
  occupationId?: string;
  otherOccupationText: string;
  patientReferredIn: boolean;
  referredFrom: null | string;
  patientType: null | string;
  treatmentSupporterName: string;
  treatmentSupporterPhone: string;
  relationToPatient: null | string;
  weightAtTreatmentStart: number;
  height: number;
  bmi: number;
  zScore: number;
  pulmonaryTbSymptoms: string[];
  tbAdenitis: string[];
  tbPericarditis: string[];
  tbMeningitis: string[];
  tbOfBonesAndJoints: string[];
  tbAbdomen: string[];
  urogenitalTuberculosis: string[];
  cutaneousTb: string[];
  riskFactors: string[];
  otherRiskFactors: string;
  tbSurveillanceComment: string;
  genexpert: null | string;
  sputumSmearMicroscopy: null | string;
  rifampicin: null | string;
  isoniazid: null | string;
  pyrazinamide: null | string;
  ethambutol: null | string;
  xray: null | string;
  patientHivStatus: null | string;
  viralLoad: number;
  cd4Count: number;
  partnerHivStatus: null | string;
  startedTbRegimenInThisVisit: boolean;
  tbTreatmentStartDate: null | string;
  tbDrugRegimen: string;
  drugSusceptibleTbRegimen: string;
  otherRegimenUsed: boolean;
  otherRegimenMedication: string[];
  startedArtRegimenInThisVisit: boolean;
  artTreatmentStartDate: null | string;
  artRegimen: string;
  otherArt: string;
  prophylaxisGiven: string;
  prophylaxisStartDate: null | string;
  nutritionSupportRecommended: string[];
  referredTo: string[];
  weightAtTreatmentEnd: number;
  careOutcome: string;
  createdBy: {
    id: string;
    fullName: string;
  };
  lastUpdatedBy: {
    id: string;
    fullName: string;
  };
  zscore: number;
  updatedAt?: string;
}

export interface ChoiceListItem {
  id: string;
  formChoiceId: string;
  label: string;
  index?: number;
  name?: string;
}

export interface PageInformation {
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  last: boolean;
  totalElements: number;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export interface PageableResponse {
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  last: boolean;
  totalElements: number;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export type TagResponse = {
  id: string;
  name: string;
  description: string;
  colour: string;
  createdAt: string;
  updatedAt: string;
};

export type Tag = {
  id: string;
  name: string;
  organizationId: string | null;
  tagType: string;
  tagCategoryId: string;
  active: boolean;
  description: string;
  tagCategory: {
    id: string;
    name: string;
    description: string;
    colour: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
  };
};

export type TagCategory = {
  id: string;
  name: string;
  description: string;
  colour: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};
