import {
  daysToWeeks,
  endOfDay,
  format,
  subHours,
  formatDistanceToNowStrict,
  startOfDay,
  intervalToDuration,
  subMonths,
  subWeeks,
  differenceInYears,
  compareDesc
} from 'date-fns';

export const getValidDate = (date: string | Date): Date | null => {
  // null is a legitimate date value in JavaScript but the backend will
  // return null for empty date values
  if (!date) return null;

  const tempDate = typeof date === 'string' ? new Date(date) : date;

  if (isNaN(tempDate.getTime())) return null;

  return tempDate;
};

export const toLongDateTimeString = (date: string | Date, errorMessage = 'Invalid Date') => {
  const validDate = getValidDate(date);

  if (!validDate) return errorMessage;

  return format(validDate, 'dd MMM yyyy · hh:mm aaa');
};

export const toLongDateString = (date: string | Date, errorMessage = 'Invalid Date') => {
  const validDate = getValidDate(date);

  if (!validDate) return errorMessage;

  return format(validDate, 'dd MMM yyy');
};

export const toLongMonthString = (date: string | Date, errorMessage = 'Invalid Date') => {
  const validDate = getValidDate(date);

  if (!validDate) return errorMessage;

  return format(validDate, 'MMM yyy');
};

export const toLongMonthOnly = (date: string | Date, errorMessage = 'Invalid Date') => {
  const validDate = getValidDate(date);

  if (!validDate) return errorMessage;

  return format(validDate, 'MMM');
};

export const toLongDateMonth = (date: string | Date, errorMessage = 'Invalid Date') => {
  const validDate = getValidDate(date);

  if (!validDate) return errorMessage;

  return format(validDate, 'MMM dd');
};

export const toFileExportDate = (date: string | Date) => {
  const validDate = getValidDate(date);

  if (!validDate) throw new Error('Invalid date supplied');

  return format(validDate, 'yyyy_MM_dd');
};

export const getFullDayOfWeek = (date: Date) => format(date, 'EEEE');

export const toTimeStringWithAMPM = (date: Date | string) => {
  const validateDate = getValidDate(date);

  if (validateDate === null) throw new Error('Invalid date supplied');

  return format(validateDate, 'hh:mm aaa');
};

export const getBackendAPIDateFormat = (date: Date) => format(date, 'yyyy-MM-dd');

export const getStartOfDayString = (date: Date) => startOfDay(date).toISOString();

export const getEndOfDayString = (date: Date) => endOfDay(date).toISOString();

/**
 * This function returns age/intervals given a date
 * It will return intervals in the following order
 * - Years
 * - Months (if the above is not available)
 * - Days (if the above are not available)
 * - Hours (if the above are not available)
 * - Minutes (if the above are not available)
 * - Seconds (if the above are not available)
 * @returns {string} e.g 1 year, 10 years, 1 second, 2 seconds etc
 */
export const getIntervalFromNow = (date: string | Date, defaultMessage = 'N/A') => {
  const dateVal = getValidDate(date);

  if (!dateVal) return defaultMessage;

  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start: new Date(),
    end: dateVal
  });

  const getSingularOrPluralUnits = (val: number, unit: string) => {
    return val === 1 ? `${val} ${unit}` : `${val} ${unit}s`;
  };

  if (years) return getSingularOrPluralUnits(years, 'year');

  if (months) return getSingularOrPluralUnits(months, 'month');

  if (days) {
    if (days > 6) return getSingularOrPluralUnits(daysToWeeks(days), 'week');

    return getSingularOrPluralUnits(days, 'day');
  }

  if (hours) return getSingularOrPluralUnits(hours, 'hour');

  if (minutes) return getSingularOrPluralUnits(minutes, 'minute');

  return getSingularOrPluralUnits(seconds, 'second');
};

export const differenceInTime = (createdDate: string) => {
  const currentDate = new Date(createdDate);
  const difference = formatDistanceToNowStrict(currentDate, { addSuffix: true });
  return difference;
};

export const oneHourBefore = (createdDate: Date) => {
  const oneHourAgo = subHours(createdDate, 1).toISOString();

  return oneHourAgo;
};

export const subMonthsFromNow = (date: Date, months: number) => {
  const currentDate = startOfDay(date);

  return subMonths(currentDate, months);
};

export const subWeeksFromNow = (date: Date, weeks: number) => {
  const currentDate = startOfDay(date);

  return subWeeks(currentDate, weeks);
};

export const calculateAge = (dob: string | Date): number => {
  return differenceInYears(new Date(), new Date(dob));
};

export const sortTimestampsDescending = (timestamps: string[]) => {
  return timestamps.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return compareDesc(dateA, dateB);
  });
};
