import { getUserRole } from '../helper/getUserRole';

const envLocal = {
  ADMIN_MODULE_URL: 'http://localhost:8080/admin',
  ROLES_URL: 'https://roles-perms-5zxb2w2ozq-ue.a.run.app/',
  INVENTORY_MODULE_URL: 'https://adonis-inventory-service-5zxb2w2ozq-ue.a.run.app/api/v1',
  BILLING_MODULE_URL: 'https://finance-module-5zxb2w2ozq-uc.a.run.app/finance-module',
  SMS_MODULE_URL: 'https://sms-messaging-5zxb2w2ozq-ue.a.run.app/'
};

const envDEV = {
  ADMIN_MODULE_URL: 'https://admin-5zxb2w2ozq-ue.a.run.app/admin',
  ROLES_URL: 'https://roles-perms-5zxb2w2ozq-ue.a.run.app/',
  INVENTORY_MODULE_URL: 'https://adonis-inventory-service-5zxb2w2ozq-ue.a.run.app/api/v1',
  BILLING_MODULE_URL: 'https://finance-module-5zxb2w2ozq-uc.a.run.app/finance-module',
  SMS_MODULE_URL: 'https://sms-messaging-5zxb2w2ozq-ue.a.run.app/'
};

const envPROD = {
  ADMIN_MODULE_URL: 'https://patient-management.ilarahealth.com/admin',
  ROLES_URL: 'https://roles-perms.prod-services.ilarahealth.com/',
  BASE_URL: 'https://patient-management.ilarahealth.com/mbalihealth/',
  INVENTORY_MODULE_URL: 'https://adonis-inventory-service-pmmbsa7ana-ue.a.run.app/api/v1',
  BILLING_MODULE_URL: 'https://finance-module-pmmbsa7ana-uc.a.run.app/finance-module',
  SMS_MODULE_URL: 'https://sms-messaging-pmmbsa7ana-ue.a.run.app/'
};

type EnvType = 'local' | 'production' | 'development' | 'staging' | 'testing';
export const env: EnvType = import.meta.env['VITE_APP_ENV'] as EnvType;

export function getEnvConfig() {
  switch (env) {
    case 'local': {
      return { env: envLocal };
    }
    case 'staging':
    case 'testing': {
      return { env: envDEV };
    }
    case 'development': {
      return { env: envDEV };
    }
    case 'production': {
      return { env: envPROD };
    }
    default: {
      throw new Error(`found unknown env ${env}`);
    }
  }
}

// const envConfig = getEnvConfig();
// 
// export const ADMIN_MODULE_URL = envConfig.env.ADMIN_MODULE_URL;
// export const INVENTORY_MODULE_URL = envConfig.env.INVENTORY_MODULE_URL;
// export const SMS_MODULE_URL = envConfig.env.SMS_MODULE_URL;
// export const BILLING_MODULE_URL = envConfig.env.BILLING_MODULE_URL;
// export const ROLES_URL = envConfig.env.ROLES_URL;
// export const HOURS = 1000 * 60 * 60;
// export const MINUTES = 1000 * 60;
// export const SECONDS = 1000;
// export const DEFAULT_PAYMENT_VALUE = 100;
// export const ADMIN_MODULE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';
// export const SUPPORT_LINK = 'https://share.hsforms.com/15w86TKBmSMGKcpJOip4b3Q4qze2';
// export const TRAINING_CENTER_LINK = 'https://support.ilarahealth.com/knowledge';

// patient contact relationship options
export const contactRelationships = [
  { label: 'Parent', value: 'PARENT' },
  { label: 'Child', value: 'CHILD' },
  { label: 'Sibling', value: 'SIBLING' },
  { label: 'Spouse', value: 'SPOUSE' },
  { label: 'Guardian', value: 'GUARDIAN' }
];

// Array of options for how a patient heard about the facility
export const hearAboutFacility = [
  { label: 'Friend/Family referral', value: 'FRIEND_OR_FAMILY_REFERRAL' },
  { label: 'SMS/text', value: 'SMS_OR_TEXT' },
  { label: 'Signage - posters, flyers, lightbox and road sign', value: 'SIGNAGE' },
  { label: 'CHV', value: 'CHV_COMMUNITY_ACTIVATION_TABLE' }, // TODO: ask backend migration of the value to just CHV
  { label: 'Public address announcement', value: 'PUBLIC_ADDRESS_ANNOUNCEMENT' },
  { label: 'Church announcement', value: 'CHURCH_ANNOUNCEMENT' },
  { label: 'TV/Radio', value: 'TV_OR_RADIO' },
  { label: 'Social Media', value: 'SOCIAL_MEDIA' },
  { label: 'Referral from Pharmacy/Hospital', value: 'PHARMACY_OR_HOSPITAL_REFERRAL' },
  { label: 'Referral from Telehealth', value: 'TELEHEALTH_PLATFORM' },
  { label: 'Referral from Local Business', value: 'REFERRAL_FROM_LOCAL_BUSINESS' },
  { label: 'Loyalty cards', value: 'LOYALTY_CARD_PROGRAM' }
];

// status of a patient
export const aliveStatus = [
  { label: 'Alive', value: 'ALIVE' },
  { label: 'Deceased', value: 'DECEASED' }
];

export const billingBlackList = [
  'Organization/16527',
  'Organization/16764',
  'Organization/16657',
  'Organization/16630',
  'Organization/16742',
  'Organization/16601',
  'Organization/16780',
  'Organization/16782',
  'Organization/16781',
  'Organization/16822',
  'Organization/16832',
  'Organization/16819',
  'Organization/16541',
  'Organization/16828',
  'Organization/16528'
];

export const VitalFlagRanges = Object.freeze({
  temperature: { high: 37.3, low: 36.1 },
  systolic: { high: 140, low: 90 },
  diastolic: { high: 90, low: 60 },
  oxygen: { high: 101, low: 94 },
  pulse: { high: 101, low: 60 },
  respiratory: { high: 21, low: 11 }
});

export const investigationRequestStatuses = {
  notCollected: '28xiCm9FisYdGm1G4AFbgW0PmVi',
  inProgress: '28xiD2tNXzOlF9d1zPfttfObr6q',
  resultsPosted: '28xiDUyKtedIC73f0h2NMg9En0L',
  rejected: '28xiDtNKkw2GNeFZL7L4XF6YpMs'
};

export const optionsDurationFacility = [
  { label: 'Today', id: '0' },
  { label: 'Yesterday', id: '1' },
  { label: 'Last 7 days', id: '7' },
  { label: 'Last 30 days', id: '30' },
  { label: 'Last 3 months', id: '90' },
  { label: 'Last 6 months', id: '180' },
  { label: 'Last 1 year', id: '365' },
  { label: 'All time', id: 'All' }
];

/* FIXME: Retrieve ID's from backend */
export const PNCchoiceListIds = {
  pnc: '2A0ZtuzbwV9pf1cZwgjUNEcSy8t',
  pregnancyRegistration: 'JE9ZRe0AxpHOpQtjJhAldWix9hn',
  pregnancyTermination: '29uwQLjVkdpNtsS9mbqgY5jaV05'
};

export const choiceListIds = {
  pnc: '2A0ZtuzbwV9pf1cZwgjUNEcSy8t',
  anc: '0FUpg4HoyNUTLRq1j8DRcRJzN3e',
  pregnancyRegistration: 'JE9ZRe0AxpHOpQtjJhAldWix9hn',
  pregnancyTermination: '29uwQLjVkdpNtsS9mbqgY5jaV05',
  pregnancySummary: 'NDDgS5zjDMNlTPdS9aGUamSvLPM',
  familyPlanning: 'jwtgwMlHjJlApLeoI327kfNbGZx',
  tuberculosisId: 'YpaFrAMlXhhfOKRgNZ2NqVjgk4T'
};

export const investigationCatergoriesIdList = {
  labInvestigationCatergoryId: '28mVNIkCzYCzY7gnkA28Bd2Nczt'
};

export const INVESTIGATION_REQUEST_LIST_KEY = 'investigation-request-list';
export const compareByTimeOptions = [
  { label: 'vs Last week', value: 'week' },
  { label: 'vs Last month', value: 'month' }
];

export const productTypeOptions = [
  { label: 'Product', value: 'product' },
  { label: 'Service', value: 'service' }
];

export const durationOptions = [
  { label: 'Last 7 days', id: '7' },
  { label: 'Last 30 days', id: '30' }
];
export const EMAIL_REGX_VALIDATION = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export enum StoreStatusEnum {
  'PENDING' = 'Pending',
  'DONE' = 'Done'
}
export enum UserTitleEnum {
  'DR' = 'DR',
  'RCO' = 'RCO',
  'RN' = 'RN',
  'MR' = 'MR',
  'MS' = 'MS'
}
export enum UserRoleEnum {
  'ROLE_ADMIN' = 'ROLE_ADMIN',
  'ROLE_CLINICIAN' = 'ROLE_CLINICIAN',
  'ROLE_AGENT' = 'ROLE_AGENT'
}
export enum UserStatusEnum {
  'PENDING' = 'PENDING',
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'BLOCKED' = 'BLOCKED'
}
// User Title options
export const userTitles = [
  { label: UserTitleEnum?.DR, value: UserTitleEnum?.DR },
  { label: UserTitleEnum?.RCO, value: UserTitleEnum?.RCO },
  { label: UserTitleEnum?.RN, value: UserTitleEnum?.RN },
  { label: UserTitleEnum?.MR, value: UserTitleEnum?.MR },
  { label: UserTitleEnum?.MS, value: UserTitleEnum?.MS }
];
// User Roles options
export const userRoles = [
  { label: getUserRole(UserRoleEnum?.ROLE_ADMIN), value: UserRoleEnum?.ROLE_ADMIN },
  { label: getUserRole(UserRoleEnum?.ROLE_CLINICIAN), value: UserRoleEnum?.ROLE_CLINICIAN },
  { label: getUserRole(UserRoleEnum?.ROLE_AGENT), value: UserRoleEnum?.ROLE_AGENT }
];
