import { atom } from 'recoil';
import {
  ANCEncounter,
  Appointment,
  ChoiceListItem,
  ICartItem,
  IDateRangeFilter,
  IInvoiceRecipientAtom,
  IPatientEncounterResponse,
  ISelectedCartItem,
  IUpdatedBill,
  IUpdatedPatientEncounterInfo,
  Patient,
  PatientEncounterInfo,
  ToastData,
  ToastVariant
} from '../../types';

export const selectedPatientAtom = atom<Patient | number>({
  key: 'patient-details',
  default: null
});

export const editPatientAtom = atom<boolean>({
  key: 'edit-patient',
  default: false
});

export const encounterAtom = atom<IUpdatedPatientEncounterInfo>({
  key: 'encounter-details',
  default: null
});

export const editAppointment = atom<boolean>({
  key: 'edit-appointment',
  default: false
});

export const selectedAppointment = atom<Appointment>({
  key: 'selected-appointment',
  default: null
});

export const newEncounter = atom<PatientEncounterInfo | object>({
  key: 'new-patient-encounter',
  default: {}
});

export const encounterHistoryAtom = atom<{ [key: string]: string }[]>({
  key: 'encounter-history',
  default: null
});

export const patientOverviewAtom = atom<boolean>({
  key: 'patient-overview',
  default: true
});

export const dashboardAtom = atom<{ [key: string]: string }[]>({
  key: 'waiting-room',
  default: null
});

export const patientEncounter = atom<IPatientEncounterResponse>({
  key: 'patient-encounter',
  default: null
});

export const completePaymentAtom = atom<boolean | undefined>({
  key: 'complete-payment',
  default: undefined
});

export const shoppingCartAtom = atom<ICartItem[]>({
  key: 'shopping-cart',
  default: []
});

export const billItemsAtom = atom<IUpdatedBill[]>({
  key: 'bill-items',
  default: []
});

export const openEncounterNav = atom<boolean>({
  key: 'patient-encounter-navigation',
  default: false
});

export const createEncounterSuccess = atom<boolean>({
  key: 'create-encounter-success',
  default: false
});

export const selectedEncounterAtom = atom<IUpdatedPatientEncounterInfo>({
  key: 'selected-encounter',
  default: null
});

export const selectedPatientForBillingAtom = atom<Patient>({
  key: 'selected-patient-for-billing',
  default: null
});

export const completePaymentModalAtom = atom<boolean>({
  key: 'complete-payment-modal-visible',
  default: false
});

export const billIdAtom = atom<string>({
  key: 'bill-id',
  default: ''
});

export const createBillItemAtom = atom<boolean>({
  key: 'create-BillItem-loading',
  default: false
});

export const fetchBillItemLoading = atom<boolean>({
  key: 'fetch-BillItem-loading',
  default: false
});

export const currentBillIdToPrintAtom = atom<string>({
  key: 'bill-to-print',
  default: ''
});

export const openBillAtom = atom<IUpdatedBill[]>({
  key: 'open-bill-atom',
  default: []
});

export const cartLoading = atom<boolean>({
  key: 'cart-loading',
  default: true
});

export const expenseHistoryFiltersAtom = atom<IDateRangeFilter[]>({
  key: 'expense-history-filters',
  default: []
});

export const expenseSummaryFiltersAtom = atom<IDateRangeFilter[]>({
  key: 'expense-summary-filters',
  default: []
});

export const resetDates = atom<string[]>({
  key: 'reset-dates',
  default: []
});

export const invoiceRecipient = atom<IInvoiceRecipientAtom>({
  key: 'current-invoice-recipient',
  default: { dueDate: null, recipientAddress: null }
});

export const ancEncounterStates = atom<ANCEncounter>({
  key: 'anc-encounter-states',
  default: {
    visitNumber: null,
    currentEga: null,
    dangerSignEvaluation: [],
    dangerSignEvaluationComments: '',
    foetalMovement: null,
    foetalMovementComments: '',
    breastExam: null,
    breastExamComments: '',
    fundalHeight: null,
    presentation: null,
    partnerHivStatus: null,
    lie: null,
    foetalHeartDetected: null,
    foetalHeartRate: null,
    foetalHeartComments: '',
    complicationsDetected: null,
    currentVisitComplications: [],
    complicationComments: '',
    bloodGroupingDone: null,
    urinalysisDone: null,
    bloodSugarResultClassification: null,
    coupleHivCounselingAndTestingDone: null,
    syphilisTestDone: null,
    syphilisTreatmentDone: null,
    tbScreening: null,
    llitnProvided: null,
    investigationComments: '',
    dewormingDone: null,
    malariaMedicationDone: null,
    tdVaccinationDone: null,
    preventiveServicesComments: '',
    ifasGiven: null,
    calciumGiven: null,
    supplementationComments: '',
    whoStage: null,
    artComments: '',
    viralLoad: null,
    onArvBeforeFirstAncVisit: null,
    startedOnHaartThisVisit: null,
    cotrimoxazoleProvided: null,
    prophylaxisComments: '',
    referralTo: null,
    reasonForReferral: [],
    referralComments: '',
    nextAppointment: null
  }
});

export const selectedCartItemAtom = atom<ISelectedCartItem>({
  key: 'selected-cart-item',
  default: null
});

export const toastDataAtom = atom<ToastData>({
  key: 'taost-data',
  default: {
    open: false,
    title: '',
    variant: ToastVariant.success,
    description: ''
  }
});

export const tbCareChoiceListAtom = atom<{ [key: string]: ChoiceListItem[] }>({
  key: 'tb-care-choice-list-atom',
  default: null
});

export const familyPlanningChoicesList = atom({
  key: 'fam-planning-choices-list',
  default: null
});
