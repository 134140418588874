import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Label } from './CustomInput';
import { FormControl } from '@mui/material';
import { phoneInputStyles } from '../hooks/useSharedStyles';

type Props = {
  onChange: (val: any) => void;
  value: string;
  helperText?: string;
  error?: string;
  label?: string | JSX.Element;
  name?: string;
  placeholder?: string;
  className?: (val: any) => void;
  sx?: any;
  readOnly?: boolean;
};

const CustomPhoneInput = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  sx,
  readOnly
}: Props) => {
  const [focus, setFocus] = useState(false);
  const styleProps = {
    focus: focus,
    error: error
  };
  const classes = phoneInputStyles(styleProps);
  return (
    <>
      <FormControl fullWidth>
        {label && (
          <Label htmlFor={`input-${name}`} style={{ color: focus ? '#2C3242' : '#818287' }}>
            {label}
          </Label>
        )}

        <PhoneInput
          international
          defaultCountry="KE"
          placeholder={placeholder}
          value={value}
          name={name}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          className={focus ? classes.phoneInput : classes.phoneInputErr}
          onChange={onChange}
          rules={{ required: true }}
          error={error}
          sx={sx}
          disabled={readOnly}
        />
      </FormControl>
    </>
  );
};

export default CustomPhoneInput;
