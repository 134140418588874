import React from 'react';
import { useLoginInfo } from '../hooks/useAppContext';
import { Role } from '../tools/roles';
import { Redirect } from 'wouter';

type Props = {
  permission: any;
  children?: any;
};

const RoleGuard = ({ permission, children }: Props) => {
  const loginInfo = useLoginInfo();
  const userPermissions: any = loginInfo?.permissions ? loginInfo?.permissions?.permissions : [];
  const hasRight = new Role(userPermissions).hasRight(permission);
  if (!hasRight) {
    return <Redirect to="/../unauthorized" />;
  }
  return <>{children}</>;
};

export default RoleGuard;
