import * as React from 'react';
import Error from './Error';
import ErrorImage from '../../assets/404-img.svg';

class HandleErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Error
          image={ErrorImage}
          title="Error"
          subtitle="An error occured, this page could not be loaded"
          text="This page could not be loaded, please ensure you have internet connection and try reloading this page again"
        />
      );
    }
    return this.props.children as any;
  }
}

export default HandleErrorBoundary;
