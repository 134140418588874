import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#23369C 0% 0% no-repeat padding-box',
    maxWidth: 220,
    padding: '7.5px',
    borderRadius: '0 4px 4px 0'
  }
}));

type Prop = {
  children: any;
  label: string;
};

export default function CustomToolTipExtended({ children, label }: Prop) {
  return (
    <div>
      <HtmlTooltip
        placement="right-start"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-1, -14]
              }
            }
          ]
        }}
        title={
          <React.Fragment>
            <p className="text-ilara-white text-left font-secondary-bold text-sm cursor-pointer">
              {label}
            </p>
          </React.Fragment>
        }
      >
        {children}
      </HtmlTooltip>
    </div>
  );
}
