import { IconButton } from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  result: any;
  onClick?: (val: any) => any;
  sx?: any;
  hideIcon?: boolean;
  iconSx?: any;
  className?: string;
  index?: number;
};

const Chip = ({ result, onClick, sx, hideIcon, iconSx, className, index }: Props) => {
  return (
    <div
      className={`${className} rounded w-fit py-2 px-3 font-primary-font flex items-center bg-ilara-blue-light`}
      style={sx}
    >
      <p className="text-xs font-medium font-primary-font mr-2 text-ilara-blue">{result}</p>
      {hideIcon ? null : (
        <IconButton sx={{ padding: 0 }} onClick={onClick} data-testid={`deleteButton-${index}`}>
          <ClearIcon
            sx={{ width: 13, height: 13, color: 'var(--ilara-blue)', ...iconSx }}
            fontSize="small"
          />
        </IconButton>
      )}
    </div>
  );
};

export default Chip;
