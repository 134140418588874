import { createStyles, makeStyles } from '@mui/styles';
import React, { useState, useEffect, useReducer } from 'react';
import NavBar from '../../components/Navbar';
import { createTheme } from '@mui/material/styles';
import CustomPhoneInput from '../../components/PhoneInput';
import { ErrorType, ToastVariant } from '../../types';
import { useForm } from 'react-hook-form';
import { useLocation } from 'wouter';
import clsx from 'clsx';
import { useRef } from 'react';
import { Link } from 'wouter';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomLoader from '../../components/CustomLoader';
import { UserRoleEnum, userRoles, userTitles } from '../../tools/constants';
import useBeforeUnload from '../../hooks/useBeforeUnload';
import TwindButton from '../../components/TwindButton';
import { toastDataAtom } from '../../tools/Atoms/atoms';
import { useSetRecoilState } from 'recoil';
import IlaraInput from '../../components/IlaraInput';
import { MainTitle } from '../../components/Titles';
import CustomCombobox from '../../components/ComboBox';
import axiosInstance from '../../helper/axios';
import { validateEmailAddress } from '../../helper/ValidateEmail';
import { getCookie } from '../../helper/cookie';
import { jwtDecode } from 'jwt-decode';

const theme = createTheme();

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      paddingBottom: theme.spacing(1),
      textDecoration: 'none',
      color: '#818287',
      fontSize: '14px',
      fontFamily: 'Satoshi-Bold',
      fontWeight: 'bold',
      paddingLeft: theme.spacing(2),
      borderLeft: '0.5px solid #818287',
      '&:hover': {
        color: '#2A45CD',
        borderLeft: '1px solid #2A45CD'
      },
      '&:active': {
        color: '#2A45CD',
        borderLeft: '1px solid #2A45CD'
      }
    },

    sectionTitle: {
      margin: 0,
      fontFamily: 'var(--Secondary-Bold)',
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#2C3242'
    },

    test: {
      color: '#2A45CD',
      borderLeft: '1px solid #2A45CD'
    },

    divider: {
      width: '100%',
      [theme.breakpoints.down(1025)]: {
        display: 'none'
      }
    },

    relationWrapper: {
      [theme.breakpoints.between(767, 1025)]: {
        width: '96%',
        marginLeft: '16px',
        marginTop: '8px'
      }
    }
  })
);

const CreateUser = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const userEmail = queryParams.get('userEmail');
  const [, setLocation] = useLocation();
  const classes = useStyles();
  const formRef = useRef(null);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState({
    lastName: '',
    firstName: '',
    middleName: ''
  });
  const [values, setValues] = useState({
    title: '',
    role: '',
    emailAddress: '',
    password: ''
  });

  const [saveChanges, setSaveChanges] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState('');
  const [oldEmail, setOldEmail] = useState(userEmail);
  const { handleSubmit } = useForm();
  const [firstNameError, setFirstNameError] = useState<false | ErrorType>(false);
  const [lastNameError, setLastNameError] = useState<false | ErrorType>(false);
  const [titleError, setTitleError] = useState<false | ErrorType>(false);
  const [roleError, setRoleError] = useState<false | ErrorType>(false);
  const [passwordError, setPasswordError] = useState<false | ErrorType>(false);
  const [emailError, setEmailError] = useState<false | ErrorType>(false);
  const [isLoadingCreateUser, setIsLoadingCreateUser] = useState(false);
  const [isLoadingUpdateUser, setIsLoadingUpdateUser] = useState(false);
  const [isLoadingFindUserById, setIsLoadingFindUserById] = useState(false);
  const setToastData = useSetRecoilState(toastDataAtom);

  useEffect(() => {
    if (
      !emailError &&
      !phoneError &&
      !passwordError &&
      !firstNameError &&
      !lastNameError &&
      !titleError &&
      !roleError
    ) {
      setSaveChanges(false);
    }
  }, [emailError, firstNameError, lastNameError, passwordError, phoneError, titleError, roleError]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setEmailError(false);
  };
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setTitleError(false);
  };
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setRoleError(false);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setPasswordError(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName({ ...name, [event.target.name]: event.target.value });
    setFirstNameError(false);
    setLastNameError(false);
  };
  const handleCreateUser = () => {
    setIsLoadingCreateUser(true);
    const requestBody = {
      firstName: name?.firstName,
      lastName: name?.lastName,
      middleName: name?.middleName,
      title: values?.title,
      role: values?.role,
      email: values?.emailAddress,
      password: values?.password,
      contactNumber: phone
    };
    try {
      axiosInstance
        .post('systemUsers/addSystemUser', requestBody)
        .then((response: any) => {
          setIsLoadingCreateUser(false);
          if (response?.status === 200) {
            setToastData({
              open: true,
              variant: ToastVariant.success,
              title: 'Success',
              description: 'New user created successfully!'
            });
            setLocation('usersList');
          }
          // window?.location?.reload();
        })
        .catch((e) => {
          // If login failed
          setIsLoadingCreateUser(false);
          // Handle error, maybe display an error message
          setToastData({
            open: true,
            variant: ToastVariant.error,
            title: 'Error',
            description: e?.response?.data
          });
        });
    } catch (error) {
      // console.error('Error occurred:', error);
      setIsLoadingCreateUser(false);
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while creating new user!'
      });
      // Handle error, maybe display an error message
    }
  };
  const handleUpdateUser = () => {
    setIsLoadingUpdateUser(true);
    const requestBody = {
      firstName: name?.firstName,
      lastName: name?.lastName,
      middleName: name?.middleName,
      title: values?.title,
      role: values?.role,
      email: values?.emailAddress,
      contactNumber: phone,
      password: values.password
    };
    try {
      axiosInstance
        .put(`systemUsers/updateSystemUser/${oldEmail}/`, requestBody)
        .then((response: any) => {
          setIsLoadingUpdateUser(false);
          if (response?.status === 200) {
            setToastData({
              open: true,
              variant: ToastVariant.success,
              title: 'Success',
              description: 'User updated successfully!'
            });
            setLocation('usersList');
          }
          // window?.location?.reload();
        })
        .catch((e) => {
          // If login failed
          setIsLoadingUpdateUser(false);
          // Handle error, maybe display an error message
          setToastData({
            open: true,
            variant: ToastVariant.error,
            title: 'Error',
            description: e?.response?.data
          });
        });
    } catch (error) {
      // console.error('Error occurred:', error);
      setIsLoadingUpdateUser(false);
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while updating a user!'
      });
      // Handle error, maybe display an error message
    }
  };
  const onSubmit = () => {
    const errorExists = {
      firstName: false,
      lastName: false,
      title: false,
      role: false,
      password: false,
      phone: false,
      emailAddress: false
    };
    if (!name.firstName) {
      errorExists.firstName = true;
      setFirstNameError({ message: 'First name is required' });
    }
    if (!name.lastName) {
      errorExists.lastName = true;
      setLastNameError({ message: 'Last name is required' });
    }

    if (!values.title) {
      errorExists.title = true;
      setTitleError({ message: 'Title is required' });
    }
    if (!values.role) {
      errorExists.role = true;
      setRoleError({ message: 'Role is required' });
    }
    if (!values.password && !userEmail) {
      errorExists.password = true;
      setPasswordError({ message: 'Password is required' });
    }
    if (!phone) {
      errorExists.phone = true;
      setPhoneError('Phone number is required');
    } else if (!isValidPhoneNumber(phone)) {
      errorExists.phone = true;
      setPhoneError('Valid phone number is required');
    }
    if (!values?.emailAddress) {
      errorExists.emailAddress = true;
      setEmailError({ message: 'Email is required' });
    }
    const emailAddress = values.emailAddress;

    if (emailAddress && !validateEmailAddress(emailAddress)) {
      errorExists.emailAddress = true;
      setEmailError({ message: 'Valid email address is required' });
    }
    type Keys = keyof typeof errorExists;
    const firstError = Object.keys(errorExists).find((error) => errorExists[error as Keys]);
    const firstErrorElement = document.querySelector(`[name=${firstError}]`);

    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (
      !errorExists?.emailAddress &&
      !errorExists?.firstName &&
      !errorExists?.lastName &&
      !errorExists?.password &&
      !errorExists?.phone &&
      !errorExists?.title
    ) {
      if (userEmail) {
        handleUpdateUser();
      } else {
        handleCreateUser();
      }
    } else {
      setSaveChanges(true);
    }
  };

  const personalDetails = useRef(null);

  const patientLinks = [
    {
      label: 'Personal Details',
      path: '#personal-details',
      refLink: personalDetails
    }
  ];
  const [active, setActive] = useState(0);
  const handleActive = (index: number) => {
    setActive(index);
  };
  useEffect(() => {
    if (userEmail) {
      try {
        setIsLoadingFindUserById(true);
        axiosInstance
          .get(`systemUsers/findSystemUserById/${userEmail}`)
          .then((response: any) => {
            setIsLoadingFindUserById(false);
            if (response?.status === 200) {
              setValues({
                title: response?.data?.title,
                role: response?.data?.role,
                emailAddress: response?.data?.username,
                password: ''
              });
              setName({
                firstName: response?.data?.firstName,
                middleName: response?.data?.middleName,
                lastName: response?.data?.lastName
              });
              setPhone(response?.data?.contactNumber);
            }
          })
          .catch((e) => {
            // If login failed
            setIsLoadingFindUserById(false);
            // Handle error, maybe display an error message
            setToastData({
              open: true,
              variant: ToastVariant.error,
              title: 'Error',
              description: e?.response?.data
            });
          });
      } catch (error) {
        // console.error('Error occurred:', error);
        setIsLoadingFindUserById(false);
        setToastData({
          open: true,
          variant: ToastVariant.error,
          title: 'Error',
          description: 'Some error occured fetching user details!'
        });
        // Handle error, maybe display an error message
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);
  const handleScrollDown = (ref: any) => {
    const offsetTop = active === 2 ? 1000 : 25;
    window.scrollTo({
      top: ref.current.offsetTop + offsetTop,
      behavior: 'smooth'
    });
  };

  const handleClick = (index: number, refLink: object) => {
    handleScrollDown(refLink);
    handleActive(index);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div className="sticky top-0 bg-ilara-white z-[1] mb-5 w-full">
        <div className="pr-12">
          <NavBar />
        </div>

        <div
          className="flex justify-between items-center pr-[89px] pl-8 border border-ilara-grey-mid z-[1] h-16 bg-ilara-white box-border
        tablet:top-[55px] tablet:justify-start tablet:flex-col tablet:h-[120px] tablet:items-start tablet:p-4 smallTablet:!max-w-full"
        >
          <MainTitle title={userEmail ? 'Update User' : 'New User Registration'} />
          <div className="flex space-x-4 smallMobile:justify-between">
            <TwindButton onClick={() => setLocation('usersList')} type="reset" variant="alternate">
              Dismiss
            </TwindButton>
            <TwindButton
              type="button"
              loading={isLoadingCreateUser || isLoadingUpdateUser}
              disabled={saveChanges || isLoadingCreateUser || isLoadingUpdateUser}
              onClick={handleSubmit(onSubmit)}
              variant="primary"
            >
              Save changes
            </TwindButton>
          </div>
        </div>
      </div>

      <div>
        {isLoadingFindUserById ? (
          <CustomLoader type="linear" />
        ) : (
          <div className="flex relative top-0 w-full">
            <div className="w-1/4 pt-8 pl-8 pr-12 tablet:hidden tablet:mr-[72px] tablet:mb-8 smallTablet:!mr-6 smallTablet:!mb-8">
              <div className="sticky top-[150px] flex flex-col">
                {patientLinks.map((link, index) => {
                  return (
                    <Link
                      onClick={() => handleClick(index, link.refLink)}
                      key={link.path}
                      href={link.path}
                      className={index === active ? clsx(classes.link, classes.test) : classes.link}
                    >
                      {link.label}
                    </Link>
                  );
                })}
              </div>
            </div>

            <form
              ref={formRef}
              className="w-full px-5 pb-[15%] relative tablet:pb-0 tablet:w-full smallMobile:!w-[95%]"
            >
              <div ref={personalDetails} className="w-[434px] pb-4 tablet:w-full tablet:p-0">
                <h4 className={classes.sectionTitle}>Personal details</h4>
                <div className="mt-2">
                  <CustomCombobox
                    comboboxType="select"
                    placeholder="Select Option"
                    name="title"
                    options={userTitles}
                    onSingleSelectChange={(option) =>
                      handleTitleChange({
                        target: { name: 'title', value: option?.value }
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                    value={userTitles.find((option) => option?.value === values.title)}
                    valueProperty="value"
                    labelProperty="label"
                    label="Title &#42;"
                    error={(titleError && titleError?.message) || ''}
                  />
                </div>
                <div className="mt-2">
                  <div className="flex flex-col tablet:flex-row tablet:align-center tablet:w-full tablet:justify-between smallTablet:!flex-col ">
                    <IlaraInput
                      placeholder="First name"
                      name="firstName"
                      className="smallTablet:!w-full tablet:w-[96%]"
                      error={firstNameError ? firstNameError.message : null}
                      value={name.firstName}
                      onChange={(e: any) => handleNameChange(e)}
                      label="Legal names of user &#42;"
                    />
                    <IlaraInput
                      name="middleName"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameChange(e)}
                      className="mt-4 smallTablet:!w-full tablet:mt-6 tablet:w-[96%]"
                      placeholder="Middle name"
                      value={name.middleName}
                    />
                  </div>

                  <IlaraInput
                    name="lastName"
                    onChange={(e: any) => handleNameChange(e)}
                    placeholder="Last name"
                    value={name.lastName}
                    error={lastNameError ? lastNameError.message : null}
                    className="mt-4 smallTablet:!w-full tablet:w-[48%]"
                  />
                </div>
                <div className="mt-2">
                  <div className="mt-2 smallTablet:flex-col tablet:mt-2 tablet:flex tablet:w-full">
                    <CustomPhoneInput
                      onChange={(val: string) => {
                        setPhone(val);
                        setPhoneError('');
                      }}
                      value={phone}
                      name="phone"
                      label="Mobile phone number &#42;"
                      error={phoneError}
                      sx={{
                        [theme.breakpoints.between(767, 1025)]: {
                          width: '95%'
                        }
                      }}
                    />

                    {phoneError !== '' && (
                      <p className="text-ilara-red text-xs font-medium mt-1 px-0 mb-2.5 font-primary-font">
                        {phoneError}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-2 tablet:flex smallTablet:!block">
                  <IlaraInput
                    placeholder="example@domain.com"
                    value={values.emailAddress}
                    // disabled={
                    //   userEmail && decodedToken?.role != UserRoleEnum.ROLE_ADMIN ? true : false
                    // }
                    name="emailAddress"
                    type="email"
                    onChange={(e: any) => handleChange(e)}
                    label="Email address*"
                    error={emailError ? emailError.message : null}
                    className="tablet:w-[96%] smallTablet:mb-2 smallTablet:!w-full"
                  />
                </div>

                <div className="mt-2 tablet:flex smallTablet:!block">
                  <IlaraInput
                    placeholder={userEmail ? 'New Password' : 'Password'}
                    value={values.password}
                    name="password"
                    type="text"
                    onChange={(e: any) => handlePasswordChange(e)}
                    label={userEmail ? 'New Password' : 'Password*'}
                    error={passwordError ? passwordError.message : null}
                    className="tablet:w-[96%] smallTablet:mb-2 smallTablet:!w-full"
                  />
                </div>

                <div className="mt-2">
                  <CustomCombobox
                    comboboxType="select"
                    placeholder="Select Option"
                    name="role"
                    options={userRoles}
                    onSingleSelectChange={(option) =>
                      handleRoleChange({
                        target: { name: 'role', value: option?.value }
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                    value={userRoles.find((option) => option?.value === values.role)}
                    valueProperty="value"
                    labelProperty="label"
                    label="Role &#42;"
                    error={(roleError && roleError?.message) || ''}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateUser;
