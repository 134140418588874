import * as React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const CustomLinearProgress = styled(LinearProgress)(() => ({
  height: 2,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#2A45CD'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#D4DAF5'
  }
}));

function CustomCircularProgress(props: CircularProgressProps) {
  return (
    <div className="relative" data-testid="circularProgress">
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: '#D4DAF5'
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      {/* <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#2A45CD',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={20}
        thickness={4}
        {...props}
      /> */}
    </div>
  );
}

interface Props {
  type: 'circular' | 'linear';
  sx?: any;
}
export default function CustomLoader({ type, sx }: Props) {
  return (
    <div className="flex-grow">
      {type == 'circular' ? (
        <CustomCircularProgress sx={sx} />
      ) : (
        <CustomLinearProgress variant="indeterminate" value={50} sx={sx} />
      )}
    </div>
  );
}
