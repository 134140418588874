import React from 'react';
import PageLayout from '../../components/PageLayout';
import PatientsListTable from '../../components/Tables/PatientsListTable';

const PatientsList = () => {
  return (
    <PageLayout pageTitle="Patients List">
      <div className="bg-ilara-grey-light min-h-screen">
        <div className="flex mt-4">
          {/* Main container */}
          <div className="flex-[1_1_860px]">
            <PatientsListTable />
            {/* Pass selectedStatus as a prop to InterviewTable for filtering */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PatientsList;
