import React, { useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import clsx from 'clsx';
import CustomLoader from '../CustomLoader';
import { toLongDateTimeString } from '../../tools/dateMethods';
import { useSetRecoilState } from 'recoil';
import { toastDataAtom } from '../../tools/Atoms/atoms';
import { ToastVariant } from '../../types';
import axiosInstance from '../../helper/axios';
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: any;
  numeric: boolean;
}

const EnhancedTableHead = () => {
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name'
    },
    {
      id: 'age',
      numeric: false,
      disablePadding: true,
      label: 'Age'
    },
    {
      id: 'gender',
      numeric: true,
      disablePadding: false,
      label: 'Gender'
    },
    {
      id: 'telephone',
      numeric: false,
      disablePadding: false,
      label: 'Telephone'
    },
    {
      id: 'dateOfRegistration',
      numeric: false,
      disablePadding: false,
      label: 'Date of Registration'
    },
    {
      id: 'dateOfFirstVirtualCOnsultation',
      numeric: false,
      disablePadding: false,
      label: 'Date of First Virtual Consultation'
    },
    {
      id: 'dateOfLastVirtualCOnsultation',
      numeric: false,
      disablePadding: false,
      label: 'Date of Last Virtual Consultation'
    }
  ];

  return (
    <thead className="bg-ilara-grey-light py-1 h-9 border-b">
      <tr>
        {headCells.map((headCell, index, arr) => (
          <th
            className={clsx(
              'text-xs font-medium text-ilara-lable-placeholder',
              index === 0 ? 'pl-8' : '',
              'text-left'
            )}
            key={index}
          >
            <p
              className={clsx(
                'text-ilara-label-placeholder',
                headCell.label === 'Expense date' && 'text-center',
                'opacity-100'
              )}
            >
              {headCell.label}
            </p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const PatientsListTable = () => {
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const setToastData = useSetRecoilState(toastDataAtom);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        axiosInstance
          .get(`/users/getAllUsers/?page=${page}&size=${rowsPerPage}`)
          .then((response: any) => {
            setIsLoading(false);
            setData(response?.data);
          })
          .catch(() => {
            setIsLoading(false);
            // Handle error, maybe display an error message
            setToastData({
              open: true,
              variant: ToastVariant.error,
              title: 'Error',
              description: 'Some error occured while fetching users!'
            });
          });
      } catch (error) {
        setToastData({
          open: true,
          variant: ToastVariant.error,
          title: 'Error',
          description: 'Some error occured while fetching users!'
        });
      }
    };

    fetchData(); // Call the fetchData function when component mounts or when page/rowsPerPage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);
  return (
    <div className=" mobile:m-2 w-full">
      {isLoading ? (
        <CustomLoader type="linear" />
      ) : (
        <>
          {data?.users?.length > 0 ? (
            <div className="min-w-[100%] max-w-[80vw] overflow-x-auto">
              <table aria-labelledby="tableTitle" className=" border w-full  min-w-[991px]">
                <EnhancedTableHead />
                <tbody>
                  {data?.users?.map((element: any, index: number) => {
                    return (
                      <tr
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className="hover:bg-ilara-grey-light border-b"
                      >
                        <td className="py-3 pl-8">
                          <p className="text-sm text-left font-medium">{element?.name}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">{element?.age}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-left font-medium text-sm">{element?.gender}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">+{element?.contactNumber}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {toLongDateTimeString(new Date(element?.insertedAt))}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {element?.firstInterviewDate
                              ? toLongDateTimeString(new Date(element?.firstInterviewDate))
                              : 'N/A'}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {element?.lastInterviewDate
                              ? toLongDateTimeString(new Date(element?.lastInterviewDate))
                              : 'N/A'}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex jsutify-center mt-[20px]">
              {/* <NoDataPage
                showBtn={true}
                buttonText="Add expenses"
                onClick={() => toggleAddExpenseModalVisible()}
              ></NoDataPage> */}
              No Data Available
            </div>
          )}
          {data?.totalUsers > 0 && (
            <TablePagination
              component="div"
              count={data?.totalUsers}
              page={page}
              rowsPerPageOptions={[10, 20, 50]}
              onPageChange={(_, next) => {
                setPage(next);
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(Number(event.target.value));
                setPage(0);
              }}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PatientsListTable;
