import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { Divider, IconButton, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/system';
import { CloseOutlined } from '@mui/icons-material';
import { useLocation } from 'wouter';
import { removeCookie } from '../helper/cookie';
// TODO: Dropdown options icon
// import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
const theme = createTheme();

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      minWidth: '150px',
      [theme.breakpoints.down(767)]: {
        borderBottom: ' 1px solid var(--ilara-grey-mid)',
        '& .MuiBackdrop-root': {
          background: 'rgba(0,0,0,0.6)'
        }
      }
    },
    paper: {
      boxShadow: '0px 3px 12px #00000029',
      borderRadius: '8px',
      transform: 'translateX(-9%) translateY(35%)',
      [theme.breakpoints.down(767)]: {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        margin: 0,
        maxWidth: '100%',
        transform: 'none',
        top: 'auto !important',
        left: '0 !important',
        borderRadius: 0
      }
    }
  })
);

type Props = {
  onClickEdit?: (val: any) => any;
  children: any;
  width?: any;
};

export default function LogoutMenu({ children, width }: Props) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 425px)');
  const [, setLocation] = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //TODO: Profile function
  // const handleEdit = (val: any) => {
  //   onClickEdit(val);
  //   handleClose();
  // };

  const handleLogout = () => {
    handleClose();
    removeCookie('AUTH_TOKEN');
    setLocation('/login');
  };

  return (
    <div>
      <div onClick={handleClick}>{children}</div>
      <Menu
        className={classes.root}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        classes={{ paper: classes.paper }}
        id="sign-out"
        keepMounted
        disableAutoFocusItem={true}
        disableScrollLock={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        PaperProps={{ style: { width: isMobile ? '100%' : width || 130 } }}
        onClose={handleClose}
      >
        <div className="hidden smallTablet:block">
          <div className="text-right">
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </div>
          <Divider variant="middle" />
        </div>
        {/* TODO: Drop down options  */}
        {/* <MenuItem
          sx={{
            fontVariant: 'normal',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '32px',
            fontWeight: '500',
            fontFamily: 'var(--Primary-Font)',
            letterSpacing: '-0.17px',
            color: '#2C3242',
            padding: '0 0 0 16px',
            opacity: 1
          }}
          onClick={(e: any) => handleEdit(e)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PersonOutlineOutlinedIcon sx={{ fontSize: '1.2em', verticalAlign: 'middle' }} />
            <Box sx={{ m: 0.5 }} />
            Profile
          </Box>
        </MenuItem>
        <MenuItem
          sx={{
            fontVariant: 'normal',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '32px',
            fontWeight: '500',
            fontFamily: 'var(--Primary-Font)',
            letterSpacing: '-0.17px',
            color: '#2C3242',
            padding: '0 0 0 16px',
            opacity: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsOutlinedIcon sx={{ fontSize: '1.2em', verticalAlign: 'middle' }} />
            <Box sx={{ m: 0.5 }} />
            Settings
          </Box>
        </MenuItem> */}
        {/* <Divider variant="middle" /> */}
        <MenuItem
          sx={{
            fontVariant: 'normal',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '32px',
            fontWeight: '500',
            fontFamily: 'var(--Primary-Font)',
            letterSpacing: '-0.17px',
            color: '#2C3242',
            padding: '0 0 0 16px',
            opacity: 1
          }}
          onClick={handleLogout}
        >
          <div className="flex items-center">
            <PowerSettingsNewOutlinedIcon sx={{ fontSize: '1.2em', verticalAlign: 'middle' }} />
            <div className="m-1" />
            Sign out
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
