import React from 'react';
import Logo from '../../assets/error-logo.svg';
import { CardMedia } from '@mui/material';
import { useLocation } from 'wouter';
import TwindButton from '../TwindButton';

type Props = {
  title: string;
  subtitle: string;
  text: string;
  image: string;
};

const Error = ({ title, subtitle, text, image }: Props) => {
  const [, setLocation] = useLocation();
  const navigateDashboard = () => {
    setLocation('/dashboard');
  };

  return (
    <div className="w-full h-screen pe-52 ps-28 smallTablet:px-4 desktop:px-14 mt-8">
      <div
        onClick={navigateDashboard}
        className="flex items-center no-underline mb-28 tablet:w-full tablet:mb-16 mobile:hidden"
      >
        <CardMedia
          sx={{ width: 38 }}
          component="img"
          height={37}
          width={38}
          image={Logo}
          alt="ilara logo"
        />
        <p className="ml-1 font-secondary-black text-lg overflow-hidden whitespace-nowrap uppercase select-none text-ilara-black">
          ILARA HEALTH
        </p>
      </div>
      <div className="flex smallTablet:flex-col-reverse">
        <div className="w-1/2 smallTablet:w-full">
          <p className="text-7xl text-ilara-blue font-bold font-primary-font smallTablet:text-6xl smallTablet:text-center">
            {title}
          </p>
          <div className="mr-3 desktopLg:max-w-md my-12 smallTablet:text-center smallTablet:w-full">
            <p className="mb-2 text-base font-medium font-primary-font text-ilara-blue">
              {subtitle}
            </p>
            <p className="text-sm font-medium font-primary-font text-ilara-black opacity-50">
              {text}
            </p>
          </div>
          <div className="flex w-full">
            <TwindButton variant="alternate" onClick={navigateDashboard}>
              Back to Dashboard
            </TwindButton>
          </div>
        </div>
        <div className="w-1/3 h-1/5 smallTablet:!w-11/12 smallTablet:my-12 desktop:w-1/2 text-center">
          <CardMedia component="img" width="100%" height="100%" image={image} alt="error img" />
        </div>
      </div>
    </div>
  );
};

export default Error;
