import { ItemData, POJO } from '../types';
import { optionsDurationFacility, VitalFlagRanges } from './constants';
import { getEndOfDayString, getStartOfDayString } from './dateMethods';

/**
 * copies an object so that the new object
 * does not share reference with the old one
 * @param obj the object to be cloned
 * @param newFields additional fields to add to the clone
 */
export function cloneObject<T extends { [key: string]: any }>(obj: T, newFields?: Partial<T>) {
  const internal: POJO = {};
  const clone: POJO = Object.assign(internal, obj);
  if (newFields) {
    for (const [k, val] of Object.entries(newFields)) {
      clone[k] = val;
    }
  }
  return clone as T;
}

// capitalize the first letter
export function capitalize(string: string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const formatName = (name: { firstName: string; lastName: string }) => {
  return `${capitalize(name?.firstName?.trim())}${' '}${capitalize(name?.lastName?.trim())}`;
};

export const addressFormat = (address: any) => {
  if (address) {
    if (address.county && address.residenceArea) {
      return address.residenceArea + ', ' + address.county;
    } else if (address.subCounty && address.county) {
      return address.subCounty + ', ' + address.county;
    } else if (address.subCounty && !address.county) {
      return address.subCounty;
    } else if (!address.subCounty && address.county) {
      return address.county;
    } else {
      return '';
    }
  }
};

export const mapVitalId = (vitalTypes: any[], vitalFormData: any, patientEncounterId: string) => {
  const mappedIds = vitalTypes.reduce((prev, curr) => {
    const baseKey = removeWhiteSpaces(curr.name);
    const key = baseKey.split(' ')[0].toLowerCase();
    prev[key] = curr.id;
    return prev;
  }, {});

  const vitalGroup = [];

  for (const i in vitalFormData) {
    if (i in mappedIds && vitalFormData[i]) {
      const vital = {
        vitalTypeId: mappedIds[i],
        value: vitalFormData[i],
        patientEncounterId,
        dateCaptured: new Date()
      };

      vitalGroup.push(vital);
    }
  }

  return vitalGroup;
};

export const checkDateBeforeToday = (date: any) => {
  if (!date) {
    return false;
  }
  return new Date(date) < new Date(new Date());
};

export const getRouterParam = (location: string): any => {
  return location.split('/').slice(-1)[0];
};

export const removeWhiteSpaces = (string: string) => {
  return string.replace(/ +(?= )/g, '');
};

type vitalTypes = 'temperature' | 'pulse' | 'systolic' | 'diastolic' | 'oxygen' | 'respiratory';

export const createVitalFlag = (vitalType: vitalTypes, value: number) => {
  if (value == null || value == undefined) {
    return null;
  }
  switch (vitalType) {
    case 'temperature': {
      if (value < VitalFlagRanges.temperature.high && value > VitalFlagRanges.temperature.low) {
        return null;
      }
      return value >= VitalFlagRanges.temperature.high ? 'H' : 'L';
    }
    case 'pulse': {
      if (value < VitalFlagRanges.pulse.high && value > VitalFlagRanges.pulse.low) {
        return null;
      }
      return value >= VitalFlagRanges.pulse.high ? 'H' : 'L';
    }
    case 'systolic': {
      if (value < VitalFlagRanges.systolic.high && value > VitalFlagRanges.systolic.low) {
        return null;
      }
      return value >= VitalFlagRanges.systolic.high ? 'H' : 'L';
    }
    case 'diastolic': {
      if (value < VitalFlagRanges.diastolic.high && value > VitalFlagRanges.diastolic.low) {
        return null;
      }
      return value >= VitalFlagRanges.diastolic.high ? 'H' : 'L';
    }
    case 'oxygen': {
      if (value < VitalFlagRanges.oxygen.high && value > VitalFlagRanges.oxygen.low) {
        return null;
      }
      return value >= VitalFlagRanges.oxygen.high ? 'H' : 'L';
    }
    case 'respiratory': {
      if (value < VitalFlagRanges.oxygen.high && value > VitalFlagRanges.oxygen.low) {
        return null;
      }
      return value >= VitalFlagRanges.oxygen.high ? 'H' : 'L';
    }
    default:
      return null;
  }
};

export const createVital = (vitals: any[]) => {
  return vitals.reduce((prev, curr) => {
    // remove all space and replace with a single space
    const baseKey = removeWhiteSpaces(curr.vitalsType.name);
    const key = baseKey.split(' ')[0].toLowerCase();
    const requiredValidation = [
      'temperature',
      'pulse',
      'systolic',
      'diastolic',
      'oxygen',
      'respiratory'
    ];
    prev[key] = {
      unitOfMeasure: curr.vitalsType.unitOfMeasure.trim(),
      value: curr.value
    };
    return prev;
  }, {});
};

export const sortData = (data: any[]) => {
  function sort(a: any, b: any) {
    return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
  }
  const sorted = data.sort(sort);

  return sorted;
};

export const getLatestVital = (vitals: any[]) => {
  function sortIt(a: any, b: any): any {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }
  const vitalsArr = [...vitals];

  return vitalsArr?.sort(sortIt)[0];
};

export const groupPriorityList = (encounters: any[]) => {
  return encounters.reduce(
    (prev, curr: any) => {
      if (curr.vitalGroups[0]) {
        const latestVital = getLatestVital(curr.vitalGroups);
        curr.triageStatus = {
          status: latestVital.triageStatus.name,
          color: latestVital.triageStatus.colour
        };
        if (
          latestVital.triageStatus.name === 'Urgent' ||
          latestVital.triageStatus.name === 'Emergent'
        ) {
          prev.priorityList.push(curr);
        } else {
          prev.other.push(curr);
        }
      } else {
        prev.other.push(curr);
      }
      return prev;
    },
    { priorityList: [], other: [] }
  );
};

export const removeItem = (array: any[], index: number): any[] => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (i === index) {
      array.splice(i, 1);
    }
  }

  return array;
};

export const checkIfExist = (array: any[], field: string, item: any) => {
  return array.find((arr) => arr[field] === item);
};

export const commafy = (num: number) => {
  const val = num.toLocaleString();
  return `KES ${val}`;
};

export const handlePractitionerName = (id: string, data: any[]) => {
  const filtered = data?.filter((item: any) => item?.id === id);
  return filtered[0]?.fullName;
};

export const groupItems = (
  items: any[],
  groupKey: string,
  mappings: any,
  type: 'array' | 'object' = 'array',
  checkedOptions?: any
) => {
  const grouped = items.reduce((prev, curr) => {
    const key = curr[groupKey];
    // change checked status
    if (checkedOptions.checkedIds.includes(curr.id)) {
      curr.checked = true;
    } else {
      curr.checked = false;
    }
    if (!(key in prev)) {
      prev[key] = [curr];
    } else {
      prev[key].push(curr);
    }
    return prev;
  }, {});

  if (type === 'array') {
    const groupedList: any = { ...mappings };
    for (const i in grouped) {
      if (i in mappings) {
        groupedList[i].ros.push(...grouped[i]);
        if (i in checkedOptions.options) {
          groupedList[i].comment = checkedOptions?.options[i]?.comments || '';
        }
      }
    }

    const finalList = [];
    for (const i in groupedList) {
      finalList.push(groupedList[i]);
    }

    return finalList;
  } else {
    return grouped;
  }
};

export const groupTags = (tags: any[]): any[] => {
  const groupedTags: any = tags?.reduce((prev: any, curr: any) => {
    const key = curr?.tag?.tagCategoryId;
    if (!(key in prev)) {
      const obj = {
        categoryName: curr?.tag?.tagCategory?.name,
        tags: [{ newTags: curr?.tag?.name, desc: curr?.description, id: curr?.id }],
        color: curr?.tag?.tagCategory?.colour,
        description: curr?.description
      };
      prev[key] = obj;
    } else {
      prev[key].tags.push({ newTags: curr?.tag?.name, desc: curr?.description, id: curr?.id });
    }
    return prev;
  }, {});

  const newTags: any[] = [];
  for (const i in groupedTags) {
    newTags.push(groupedTags[i]);
  }

  return newTags;
};

export const formatFileSize = (x: any) => {
  const units = ['b', 'KB', 'MB', 'GB'];

  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getPaymentStatus = (status: string) => {
  switch (status) {
    case 'PAID':
      return 'Paid';
    case 'PARTIALLY_PAID':
      return 'Partially paid';
    default:
      return status;
  }
};

export const getFullName = (val: any) => {
  return `${val?.name?.prefix ? val.name.prefix.trim() + ' ' : ''}${
    val?.name?.firstName ? val.name.firstName.trim() + ' ' : ''
  }${val?.name?.middleName ? val.name.middleName.trim() + ' ' : ''}${
    val?.name?.lastName ? val.name.lastName.trim() + ' ' : ''
  }`.trim();
};
//add commas to make a display a number as a money value

export const addCommas = (number: number) =>
  number?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0.00';

export const addCommasOnly = (number: number) => number?.toLocaleString('en-US');

export const filterMapping = (filters: ItemData) => {
  const dateMin = new Date();
  const dateMax = new Date();

  if (Array.isArray(filters)) {
    return filters
      ?.map((x: ItemData) => {
        for (const i in x) {
          if (i == 'startDate') {
            return `${i}=${getStartOfDayString(x[i] as Date)}`;
          }
          if (i === 'duration') {
            if (x[i] !== 'All') {
              dateMin.setDate(dateMin.getDate() - parseInt(x[i]));
              return `startDate=${getStartOfDayString(dateMin)}&endDate=${getEndOfDayString(
                dateMax
              )}`;
            }
          }
          if (i == 'endDate') {
            return `${i}=${getEndOfDayString(x[i] as Date)}`;
          }
          // TODO: FOR BRANCH     if (i === 'branchId') {
          //   return `${i}=${(x[i] as ObjectsData).id}`;
          // }
          else {
            return '';
          }
        }
      })

      .filter(Boolean)
      .join('&');
  }
};

// Get age given birth date
export const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const daysOrMonthsOrYearFacility = (duration: string) => {
  if (duration) {
    const durationTime = optionsDurationFacility.find((option) => option?.id === duration);
    return durationTime.label;
  }
};

/**
 * Replace all the dashes in string with empty space and Capitalize first character.
 * @param str A string containing dashes ("patient-medical-history")
 * @returns string ("Patient Medical History")
 */
export const replaceDashesAndCapitalize = (string: string) => {
  const words = string.split('-');
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return titleCaseWords.join(' ');
};

/**
 * Create a URL from baseURL and Path. Remove trailing slash from baseURL and
 * leading slash from path if they exist
 * @param baseURL Base URL
 * @param path path without base URL
 * @returns joined URL
 */
export function joinUrl(baseURL: string, path: string): string {
  const cleanedBaseURL = baseURL.replace(/\/$/, '');
  const cleanedPath = path.replace(/^\//, '');
  const joinedURL = `${cleanedBaseURL}/${cleanedPath}`;
  return joinedURL;
}
