/* eslint-disable no-console */
import React, { Dispatch } from 'react';
import { createContext, useEffect, useState } from 'react';
import { cloneObject } from './tools/tools';
import type { BranchData, Organization, Practitioner, Role } from './types';
import Loading from './components/Loading';

const defaultState: {
  loggedIn: boolean;
  loginInfo: null | {
    practitioner: Practitioner;
    organization: Organization;
    permissions: Role;
    branch: BranchData;
    setBranch: Dispatch<any>;
  };
  updateState?: any;
} = {
  loggedIn: false,
  loginInfo: null
};

export const AppContext = createContext(cloneObject(defaultState));

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = ({ children }: any) => {
  const [state, setState] = useState(defaultState);
  const [branch, setBranch] = useState(null);

  const updateState = (newValue: any) => {
    setState((prev) => {
      return {
        ...prev,
        loginInfo: newValue
      };
    });
  };

  useEffect(() => {
    if (branch) {
      setState((prev) => {
        return {
          ...prev,
          loginInfo: { ...prev?.loginInfo, branch }
        };
      });
    }
  }, [branch]);

  // if (!state.loggedIn || state.loginInfo === null) {
  //   return <Loading />;
  // }

  return <AppContext.Provider value={{ ...state, updateState }}>{children}</AppContext.Provider>;
};
