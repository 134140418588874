import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion sx={props.sx} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxSizing: 'border-box',
  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme, expandIcon }) => ({
  backgroundColor: '#fff',
  flexDirection: 'row-reverse',
  minHeight: '40px',
  margin: 0,
  fontSize: '14px',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down(1025)]: {
    padding: expandIcon ? theme.spacing(0, 2) : theme.spacing(0)
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    width: 20,
    height: 20,
    color: '#2C3242',
    [theme.breakpoints.down(1025)]: {
      display: expandIcon ? 'block' : 'none'
    }
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2),
    padding: 0,
    [theme.breakpoints.down(1025)]: {
      margin: !expandIcon && 0
    }
  },
  '& .MuiAccordionSummary-root': {
    minHeight: '40px'
  },
  '& .MuiSvgIcon-root': {
    alignSelf: 'center'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: 0,
  background: '#FBFBFB 0% 0% no-repeat padding-box'
}));

type Props = {
  id: string;
  summary: React.ReactNode;
  children: React.ReactNode;
  sx?: any;
  detailsSx?: any;
  summaryActions?: React.ReactNode;
  expandIcon?: string | boolean;
  expandedAtStart?: string;
};

const CustomizedAccordion = ({
  id,
  summary,
  children,
  sx,
  summaryActions,
  detailsSx,
  expandIcon = true,
  expandedAtStart
}: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(expandedAtStart || false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion sx={sx} expanded={expanded === id} onChange={handleChange(id)}>
      <AccordionSummary
        expandIcon={expandIcon ? <ExpandMoreIcon /> : null}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <div className="flex justify-between w-full items-center">
          <div className="font-medium font-primary w-full text-sm">{summary}</div>
          <div onChange={() => setExpanded(false)}>{summaryActions}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ detailsSx }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomizedAccordion;
