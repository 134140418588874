import * as RadixToast from '@radix-ui/react-toast';
import React, { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Close from '@mui/icons-material/Close';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ToastVariant } from '../types';
import { useRecoilState } from 'recoil';
import { toastDataAtom } from '../tools/Atoms/atoms';

export default function Toast() {
  const [toastData, setToastData] = useRecoilState(toastDataAtom);
  const [animationPlayState, setAnimationPlayState] = useState('running');

  const setOpen = (open: boolean) => {
    setToastData((prev) => ({
      ...prev,
      open: open
    }));
  };

  return (
    <RadixToast.Provider>
      <RadixToast.Root
        data-testid="toast-root"
        className="border border-ilara-grey-mid bg-ilara-grey-light shadow-md rounded-lg items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
        duration={4800}
        onPause={() => setAnimationPlayState('paused')}
        onResume={() => setAnimationPlayState('running')}
        open={toastData.open}
        onOpenChange={setOpen}
      >
        <div className="relative flex">
          <div
            className={`w-11 ${
              toastData.variant === ToastVariant.error
                ? 'bg-ilara-red'
                : toastData.variant === ToastVariant.info
                ? 'bg-ilara-blue-light'
                : 'bg-ilara-green'
            } rounded-s-lg overflow-hidden flex justify-center items-center text-ilara-white`}
          >
            {toastData.variant === ToastVariant.error ? (
              <ErrorOutline fontSize="small" />
            ) : toastData.variant === ToastVariant.info ? (
              <InfoOutlinedIcon style={{ color: 'blue' }} fontSize="small" />
            ) : (
              <CheckCircleOutlineIcon fontSize="small" />
            )}
          </div>
          <div className="pt-3 pb-4 pl-4 w-80 pr-2">
            <RadixToast.Title
              data-testid="toast-title"
              className={`font-medium leading-4 pb-2 font-['Satoshi-Bold'] text-sm ${
                toastData.variant === ToastVariant.error
                  ? 'text-ilara-red'
                  : toastData.variant === ToastVariant.info
                  ? 'text-ilara-black'
                  : 'text-ilara-green'
              }`}
            >
              {toastData.title}
            </RadixToast.Title>
            <RadixToast.Description
              data-testid="toast-description"
              className="text-xs font-['inter']"
            >
              {toastData.description}
            </RadixToast.Description>
          </div>
          <div className=" w-7 pt-3 text-xs cursor-pointer" onClick={() => setOpen(false)}>
            <Close fontSize="small" />
          </div>
          <div
            className={`absolute bottom-0 left-1 h-1 right-5 w-full mr-2 rounded-bl-lg ${
              toastData.variant === ToastVariant.error
                ? 'bg-ilara-red'
                : toastData.variant === ToastVariant.info
                ? 'bg-ilara-blue-light'
                : 'bg-ilara-green'
            }`}
            style={{ animation: 'slide-in 5s linear', animationPlayState: animationPlayState }}
          ></div>
        </div>
      </RadixToast.Root>
      <RadixToast.Viewport className="[--viewport-padding:_10px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none py-3 " />
    </RadixToast.Provider>
  );
}
