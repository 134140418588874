import React, { useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import clsx from 'clsx';
import CustomLoader from '../CustomLoader';
import { useLocation } from 'wouter';
import { useSetRecoilState } from 'recoil';
import { toastDataAtom } from '../../tools/Atoms/atoms';
import { ToastVariant } from '../../types';
import axiosInstance from '../../helper/axios';
import DropdownMenu from '../DropdownMenu';
import { UserStatusEnum } from '../../tools/constants';
import { getUserRole } from '../../helper/getUserRole';
import { toLongDateTimeString } from '../../tools/dateMethods';
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: any;
  numeric: boolean;
}

const EnhancedTableHead = () => {
  const headCells: readonly HeadCell[] = [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: 'First Name'
    },
    {
      id: 'middleName',
      numeric: false,
      disablePadding: true,
      label: 'Middle Name'
    },
    {
      id: 'lastName',
      numeric: true,
      disablePadding: false,
      label: 'Last Name'
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email'
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status'
    },
    {
      id: 'lastLogin',
      numeric: false,
      disablePadding: false,
      label: 'Last Login'
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions'
    }
  ];

  return (
    <thead className="bg-ilara-grey-light py-1 h-9 border-b ">
      <tr>
        {headCells.map((headCell, index, arr) => (
          <th
            className={clsx(
              'text-xs font-medium text-ilara-lable-placeholder',
              index === 0 ? 'pl-8' : '',
              index === arr.length - 1 ? 'text-right pr-6' : 'text-left'
            )}
            key={index}
          >
            <p
              className={clsx(
                'text-ilara-label-placeholder',
                headCell.label === 'Expense date' && 'text-center',
                'opacity-100'
              )}
            >
              {headCell.label}
            </p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const UsersTable = () => {
  const [, setLocation] = useLocation();
  const [data, setData] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);
  const [isLoadingEnableDisableUser, setIsLoadingEnableDisableUser] = useState(false);
  const setToastData = useSetRecoilState(toastDataAtom);
  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      axiosInstance
        .get(`/systemUsers/getAllSystemUsers?page=${page}&size=${rowsPerPage}`)
        .then((response: any) => {
          setIsLoading(false);
          setData(response?.data);
        })
        .catch(() => {
          // If login failed
          setIsLoading(false);
          // Handle error, maybe display an error message
          setToastData({
            open: true,
            variant: ToastVariant.error,
            title: 'Error',
            description: 'Some error occured while fetching users!'
          });
        });
    } catch (error) {
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while fetching users!'
      });
    }
  };
  useEffect(() => {
    fetchData(); // Call the fetchData function when component mounts or when page/rowsPerPage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);
  const handleDeleteUser = (email: string) => {
    setIsLoadingDeleteUser(true);
    try {
      axiosInstance
        .delete(`systemUsers/deleteSystemUser/${email}`)
        .then((response: any) => {
          setIsLoadingDeleteUser(false);
          if (response?.status === 200) {
            setToastData({
              open: true,
              variant: ToastVariant.success,
              title: 'Success',
              description: 'User deleted successfully!'
            });
            fetchData();
          }
        })
        .catch((e) => {
          setIsLoadingDeleteUser(false);
          // Handle error, maybe display an error message
          setToastData({
            open: true,
            variant: ToastVariant.error,
            title: 'Error',
            description: e?.response?.data
          });
        });
    } catch (error) {
      // console.error('Error occurred:', error);
      setIsLoadingDeleteUser(false);
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while deleting a user!'
      });
      // Handle error, maybe display an error message
    }
  };
  const handleEnableDisableUser = (email: string, status: string) => {
    setIsLoadingEnableDisableUser(true);
    try {
      axiosInstance
        .put(`systemUsers/changeStatus/${email}/${status}`)
        .then((response: any) => {
          setIsLoadingEnableDisableUser(false);
          if (response?.status === 200) {
            setToastData({
              open: true,
              variant: ToastVariant.success,
              title: 'Success',
              description:
                status === UserStatusEnum?.ACTIVE
                  ? 'User enabled successfully!'
                  : 'User disabled successfully!'
            });
            fetchData();
          }
        })
        .catch((e) => {
          setIsLoadingEnableDisableUser(false);
          // Handle error, maybe display an error message
          setToastData({
            open: true,
            variant: ToastVariant.error,
            title: 'Error',
            description: e?.response?.data
          });
        });
    } catch (error) {
      // console.error('Error occurred:', error);
      setIsLoadingEnableDisableUser(false);
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while enabling a user!'
      });
    }
  };
  return (
    <div className=" mobile:m-2 w-full ">
      {isLoading || isLoadingDeleteUser ? (
        <CustomLoader type="linear" />
      ) : (
        <>
          {data?.systemUserList?.length ? (
            <div className="min-w-[100%] max-w-[80vw] overflow-x-auto">
              <table aria-labelledby="tableTitle" className=" border w-full min-w-[991px]">
                <EnhancedTableHead />
                <tbody>
                  {data?.systemUserList?.map((element: any, index: number) => {
                    return (
                      <tr
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        className="hover:bg-ilara-grey-light border-b"
                      >
                        <td className="py-3 pl-8">
                          <p className="text-sm text-left font-medium">{element?.firstName}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {element?.middleName ? element?.middleName : 'N/A'}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-left font-medium text-sm">{element?.lastName}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">{element?.title}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">{element?.email}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {getUserRole(element?.role)}
                          </p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">{element?.status}</p>
                        </td>
                        <td className="py-3">
                          <p className="text-sm text-left font-medium">
                            {' '}
                            {element?.lastLogin
                              ? toLongDateTimeString(new Date(element?.lastLogin))
                              : ''}
                          </p>
                        </td>

                        <td align="right" className="py-3 pr-4">
                          <div className="pr-2">
                            <DropdownMenu
                              items={
                                element?.status === UserStatusEnum?.ACTIVE
                                  ? [
                                      {
                                        name: 'Edit Details',
                                        onClick: () => {
                                          setLocation(`addUser?userEmail=${element?.email}`);
                                        }
                                      },
                                      {
                                        name: 'Disable User',
                                        onClick: () => {
                                          handleEnableDisableUser(
                                            element?.email,
                                            UserStatusEnum?.INACTIVE
                                          );
                                        }
                                      },
                                      {
                                        name: 'Delete User',
                                        onClick: () => {
                                          handleDeleteUser(element?.email);
                                        }
                                      }
                                    ]
                                  : [
                                      {
                                        name: 'Edit Details',
                                        onClick: () => {
                                          setLocation(`addUser?userEmail=${element?.email}`);
                                        }
                                      },

                                      {
                                        name: 'Enable User',
                                        onClick: () => {
                                          handleEnableDisableUser(
                                            element?.email,
                                            UserStatusEnum?.ACTIVE
                                          );
                                        }
                                      },
                                      {
                                        name: 'Delete User',
                                        onClick: () => {
                                          handleDeleteUser(element?.email);
                                        }
                                      }
                                    ]
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex jsutify-center mt-[20px]">
              {/* <NoDataPage
                showBtn={true}
                buttonText="Add expenses"
                onClick={() => toggleAddExpenseModalVisible()}
              ></NoDataPage> */}
              No Data Available
            </div>
          )}
          {data?.totalSystemUsers > 0 && (
            <TablePagination
              component="div"
              count={data?.totalSystemUsers}
              page={page}
              rowsPerPageOptions={[10, 20, 50]}
              onPageChange={(_, next) => {
                setPage(next);
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(Number(event.target.value));
                setPage(0);
              }}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UsersTable;
