import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import { FormControl, FormHelperText, InputBase } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { useSharedStyles } from '../hooks/useSharedStyles';
import clsx from 'clsx';

type Props = {
  placeholder?: string;
  value: string | number;
  label?: string | JSX.Element;
  onChange?: (val: any) => void;
  name?: string;
  width?: string;
  sx?: any;
  helperText?: string;
  error?: boolean;
  required?: boolean;
  className?: string;
  type?: string;
  onBlur?: (val: any) => void;
  readOnly?: boolean;
  inputProps?: object;
  inputRef?: React.Ref<any>;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  allowDecimal?: boolean;
};

const theme = createTheme();

export const Label = styled('label')({
  marginBottom: theme.spacing(1),
  fontWeight: 500
});

const CustomInputField = ({
  placeholder,
  value,
  name,
  sx,
  onBlur,
  onChange,
  helperText,
  error,
  label,
  type,
  required,
  readOnly,
  className,
  inputRef,
  endAdornment,
  startAdornment,
  allowDecimal
}: Props) => {
  const classes = useSharedStyles();
  // const [focused, setFocused] = React.useState(false);
  // const onFocus = () => setFocused(true);
  return (
    <>
      <FormControl variant="standard" fullWidth>
        {label && (
          <Label htmlFor={`input-${name}`} style={{ color: '#818287' }}>
            {label}
          </Label>
        )}
        <InputBase
          data-testid={`input-${name}`}
          id={`input-${name}`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          fullWidth
          onBlur={onBlur}
          error={error}
          name={name}
          type={type}
          sx={sx}
          required={required}
          disabled={readOnly}
          className={clsx(classes.input, className)}
          aria-describedby="component-error-text"
          inputProps={{ min: 0, ...(allowDecimal && { step: '0.1' }) }}
          inputRef={inputRef}
          endAdornment={endAdornment}
          startAdornment={startAdornment}
        />
        {error && (
          <FormHelperText className={classes.helpertext} id="component-error-text">
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
export default CustomInputField;
