import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
type MenuItemProps = {
  name: string;
  onClick?: () => void;
  color?: string;
};
type DropdownProps = {
  items: MenuItemProps[];
};
const DropdownMenu: React.FC<DropdownProps> = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ py: 0 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ style: { minWidth: 130, boxShadow: '0px 3px 12px #00000029' } }}
      >
        {items?.map(({ name, color, onClick }, index) => (
          <MenuItem
            key={index}
            onClick={(event) => {
              event.stopPropagation();
              onClick?.();
              handleClose();
            }}
            sx={{
              fontVariant: 'normal',
              fontStyle: 'normal',
              fontSize: '14px',
              lineHeight: '32px',
              fontWeight: '500',
              fontFamily: 'var(--Primary-Font)',
              letterSpacing: '-0.17px',
              color: color || '#2C3242',
              padding: '0 16px 0 16px'
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default DropdownMenu;
