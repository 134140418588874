import React from 'react';
import clsx from 'clsx';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  helperText?: string;
  allowDecimal?: boolean;
  dataTestId?: string;
  className?: string;
}

// FIXME: remove w-full
const BASE_STYLES =
  'border rounded resize-none outline-none overflow-y-hidden bg-white hover:bg-ilara-grey-light focus:border-ilara-blue text-sm font-medium focus:shadow-[0px_1px_1px_#2A45CD4D] py-[10px] px-4 placeholder:color-ilara-secondary-text w-full block h-9';

const IlaraInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      id,
      error,
      disabled,
      helperText,
      className,
      allowDecimal,
      dataTestId,
      ...props
    }: InputProps,
    ref
  ) => {
    const allowStep = allowDecimal && props.type === 'number';

    return (
      <div className="space-y-2 w-full">
        {label ? (
          <label
            className={clsx(
              'color-ilara-black block text-xs tracking-[-0.14px] font-medium leading-[15px]',
              error && 'text-ilara-red'
            )}
            data-testid="input-label"
            htmlFor={id}
          >
            {label}
          </label>
        ) : null}
        <input
          {...props}
          data-testid={dataTestId}
          aria-invalid={!!error}
          disabled={disabled}
          aria-disabled={disabled}
          id={id}
          step={allowStep ? '0.001' : undefined}
          ref={ref}
          className={clsx(BASE_STYLES, className, error && 'border-ilara-red')}
        />
        {error || helperText ? (
          <p
            className={`${
              error ? 'text-ilara-red' : 'text-ilara-secondary-text'
            } text-xs font-medium`}
            data-testid={error ? 'input-error' : 'input-helper-text'}
          >
            {error || helperText}
          </p>
        ) : null}
      </div>
    );
  }
);

IlaraInput.displayName = 'IlaraInput';

export default IlaraInput;
