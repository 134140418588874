/* eslint-disable no-console */
export class Role {
  staticPerms: string[] = [];
  permissions: any;
  constructor(perms: any) {
    this.permissions = perms;
  }

  hasPermision(action: any) {
    if (action === true) {
      return true;
    }
    // check super user
    if (this.permissions.superuser) {
      return true;
    }
    return this.permissions[action] === true;
  }

  hasRight(permission: any) {
    return this.hasPermision(permission);
  }
}

export const defaultPermissions = (page: string) => {
  if (!page) {
    return '';
  }

  const permissions: { [key: string]: string } = {
    rolesEdit: 'roles-edit',
    rolesView: 'roles-view',
    triageEdit: 'triage-edit',
    triageView: 'triage-view',
    billingEdit: 'billing-edit',
    billingView: 'billing-view',
    patientEdit: 'patient-edit',
    patientView: 'patient-view',
    rolesCreate: 'roles-create',
    bioDataEdit: 'bio-data-edit',
    rolesArchive: 'roles-archive',
    timelineView: 'timeline-view',
    triageCreate: 'triage-create',
    billingCreate: 'billing-create',
    diagnosesEdit: 'diagnoses-edit',
    diagnosesView: 'diagnoses-view',
    encounterView: 'encounter-view',
    inventoryEdit: 'inventory-edit',
    inventoryView: 'inventory-view',
    patientCreate: 'patient-create',
    triageArchive: 'triage-archive',
    billingArchive: 'billing-archive',
    patientArchive: 'patient-archive',
    diagnosesCreate: 'diagnoses-create',
    encounterCreate: 'encounter-create',
    examinationEdit: 'examination-edit',
    examinationView: 'examination-view',
    inventoryCreate: 'inventory-create',
    patientTagEdit: 'patient-tag-edit',
    patientTagView: 'patient-tag-view',
    appointmentsEdit: 'appointments-edit',
    appointmentsView: 'appointments-view',
    diagnosesArchive: 'diagnoses-archive',
    encounterArchive: 'encounter-archive',
    inventoryArchive: 'inventory-archive',
    organizationEdit: 'organization-edit',
    organizationView: 'organization-view',
    practitionerEdit: 'practitioner-edit',
    practitionerView: 'practitioner-view',
    examinationCreate: 'examination-create',
    patientTagCreate: 'patient-tag-create',
    prescriptionsEdit: 'prescriptions-edit',
    prescriptionsView: 'prescriptions-view',
    appointmentsCreate: 'appointments-create',
    complaintsHpiEdit: 'complaints-hpi-edit',
    complaintsHpiView: 'complaints-hpi-view',
    examinationArchive: 'examination-archive',
    investigationsEdit: 'investigations-edit',
    investigationsView: 'investigations-view',
    organizationCreate: 'organization-create',
    patientTagArchive: 'patient-tag-archive',
    practitionerCreate: 'practitioner-create',
    adminDashboardView: 'admin-dashboard-view',
    appointmentsArchive: 'appointments-archive',
    encounterCloseEdit: 'encounter-close-edit',
    organizationArchive: 'organization-archive',
    practitionerArchive: 'practitioner-archive',
    prescriptionsCreate: 'prescriptions-create',
    saveVisitFormEdit: 'save-visit-form-edit',
    userManagementView: 'user-management-view',
    complaintsHpiCreate: 'complaints-hpi-create',
    prescriptionsArchive: 'prescriptions-archive',
    complaintsHpiArchive: 'complaints-hpi-archive',
    reviewOfSystemsEdit: 'review-of-systems-edit',
    reviewOfSystemsView: 'review-of-systems-view',
    visitInformationEdit: 'visit-information-edit',
    visitInformationView: 'visit-information-view',
    clinicInformationEdit: 'clinic-information-edit',
    clinicInformationView: 'clinic-information-view',
    diagnosesReferralEdit: 'diagnoses-referral-edit',
    investigationsLabView: 'investigations-lab-view',
    medicationHistoryEdit: 'medication-history-edit',
    medicationHistoryView: 'medication-history-view',
    diagnosesSickNoteEdit: 'diagnoses-sick-note-edit',
    reviewOfSystemsCreate: 'review-of-systems-create',
    inventoryStockTakeEdit: 'inventory-stock-take-edit',
    inventoryStockTakeView: 'inventory-stock-take-view',
    medicationHistoryCreate: 'medication-history-create',
    reviewOfSystemsArchive: 'review-of-systems-archive',
    investigationsResultEdit: 'investigations-result-edit',
    medicationHistoryArchive: 'medication-history-archive',
    inventoryStockTakeCreate: 'inventory-stock-take-create',
    investigationsImagingView: 'investigations-imaging-view',
    investigationsRequestEdit: 'investigations-request-edit',
    inventoryStockTakeArchive: 'inventory-stock-take-archive',
    investigationsResultCreate: 'investigations-result-create',
    patientMedicalHistoryEdit: 'patient-medical-history-edit',
    patientMedicalHistoryView: 'patient-medical-history-view',
    treatmentPrescriptionsEdit: 'treatment-prescriptions-edit',
    treatmentPrescriptionsView: 'treatment-prescriptions-view',
    inventoryStockMovementEdit: 'inventory-stock-movement-edit',
    inventoryStockMovementView: 'inventory-stock-movement-view',
    investigationsRequestCreate: 'investigations-request-create',
    investigationsResultArchive: 'investigations-result-archive',
    investigationsRequestArchive: 'investigations-request-archive',
    patientMedicalHistoryCreate: 'patient-medical-history-create',
    inventoryStockMovementCreate: 'inventory-stock-movement-create',
    patientMedicalHistoryArchive: 'patient-medical-history-archive',
    inventoryStockMovementArchive: 'inventory-stock-movement-archive',
    reportsExternalView: 'reports-external-view',
    expensesEdit: 'expenses-edit',
    expensesView: 'expenses-view',
    expensesArchive: 'expenses-archive',
    expensesCreate: 'expenses-create',
    expensesSummaryView: 'expenses-summary-view',
    billingPriceEdit: 'billing-price-edit'
  };

  return permissions[page];
};
