import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'wouter';
import SymptomDetails from '../services/VirtualConsultations/SymptomDetails';
import VirtualConsultations from '../services/VirtualConsultations/VirtualConsultations';
import Login from '../services/Auth/Login';
import PatientsList from '../services/Patients/PatientsList';
import { getCookie } from '../helper/cookie';
import UsersList from '../services/Users/UsersList';
import CreateUser from '../services/Users/CreateUser';
// import Signup from '../services/Auth/Signup';
import ForgotPassword from '../services/Auth/ForgotPassword';
import EmailVerification from '../services/Auth/EmailVerification';

function Routes() {
  const isAuthenticated = !!getCookie('AUTH_TOKEN'); // Check if token exists

  const PrivateRoute: FC<{ path: string; component: FC }> = ({ path, component }) => {
    return isAuthenticated ? <Route path={path} component={component} /> : <Redirect to="/login" />;
  };

  return (
    <Switch>
      <Route path="/login">{isAuthenticated ? <Redirect to="/" /> : <Login />}</Route>
      {/* <Route path="/signup">{isAuthenticated ? <Redirect to="/" /> : <Signup />}</Route> */}
      <Route path="/forgotpassword">
        {isAuthenticated ? <Redirect to="/" /> : <ForgotPassword />}
      </Route>

      <Route path="/passwordReset/reset">
        {isAuthenticated ? <Redirect to="/" /> : <EmailVerification />}
      </Route>

      <PrivateRoute path="/patientsList" component={PatientsList} />
      <PrivateRoute path="/virtualConsultations" component={VirtualConsultations} />
      <PrivateRoute path="/symptomDetails" component={SymptomDetails} />
      <PrivateRoute path="/usersList" component={UsersList} />
      <PrivateRoute path="/addUser" component={CreateUser} />
      <Route path="/">{isAuthenticated ? <PatientsList /> : <Redirect to="/login" />}</Route>
      <Route>{isAuthenticated ? <PatientsList /> : <Redirect to="/login" />}</Route>
    </Switch>
  );
}

export default Routes;
