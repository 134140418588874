import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CustomAvatar from './Avatar';
import { useLoginInfo } from '../hooks/useAppContext';
import { formatName } from '../tools/tools';
import { createTheme } from '@mui/material/styles';
import LogoutMenu from './LogoutMenu';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles } from '@mui/styles';
import { CardMedia } from '@mui/material';
import logo from '../assets/dark-logo.svg';
import { useLocation } from 'wouter';
import { jwtDecode } from 'jwt-decode';
import { getCookie } from '../helper/cookie';

type Props = {
  notifications?: number;
  styles?: { position?: string };
  orderBanner?: boolean;
  onClose?: () => void;
};

const useStyles = makeStyles({
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    fontFamily: 'Inter',
    fontSize: '12px',
    marginLeft: '3px',
    cursor: 'pointer'
  },
  textClass: {
    fontSize: '12px',
    textAlign: 'center',
    letterSpacing: '-0.14px',
    color: '#2945CD',
    fontFamily: 'Inter',
    fontWeight: 500,
    opacity: 1
  }
});

const NavBar = ({ styles, orderBanner, onClose }: Props) => {
  const [, setLocation] = useLocation();
  const loginInfo = useLoginInfo();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const theme = createTheme();
  const openLogoutMenu = (e: any) => {
    e.preventDefault();
  };

  // Decode the token using jwt-decode
  const decodedToken: any = jwtDecode(getCookie('AUTH_TOKEN'));

  const companyName = () => {
    return (
      <p className="ml-1 font-secondary-bold text-lg overflow-hidden whitespace-nowrap uppercase text-ilara-blue">
        ILARA HEALTH
      </p>
    );
  };

  if (isMobile) return <div className="h-16" />;

  return (
    <nav className="z-40 sticky grow top-0 w-full">
      <AppBar
        sx={{
          boxShadow: 'none',
          [theme.breakpoints.up(767)]: {
            borderBottom: '1px solid rgb(227, 227, 227)'
          },
          color: '#000',
          position: styles ? styles.position : 'static',
          background: '#fff',
          top: '0'
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginRight: '8px'
          }}
          // className="bg-black"
        >
          {!isMobile && <div className="w-[70%] tablet:hidden">{/* <Searchbar /> */}</div>}
          {!isMobile ? (
            <>
              <LogoutMenu>
                <div
                  className="flex cursor-pointer items-center"
                  onClick={(e) => openLogoutMenu(e)}
                >
                  {/* TODO: notification icon  */}
                  {/* <IconButton size="large" color="inherit">
                  <Badge badgeContent={notifications} color="error">
                    <NotificationsNoneOutlinedIcon />
                  </Badge>
                </IconButton> */}
                  <CustomAvatar
                    show
                    showIcon={true}
                    prefix={loginInfo?.practitioner?.name.prefix}
                    fullName={formatName({
                      firstName: decodedToken?.firstName,
                      lastName: decodedToken?.lastName
                    })}
                    description={loginInfo?.practitioner.practitionerRole[0].roles[0]}
                  />
                </div>
              </LogoutMenu>
            </>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default NavBar;
