import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout';
import InterviewTable from '../../components/Tables/InterviewTable';
import { StoreStatusEnum } from '../../tools/constants';

const VirtualConsultations = () => {
  const [selectedStatus, setSelectedStatus] = useState(''); // State to manage selected status

  return (
    <PageLayout pageTitle="Virtual Consultations List">
      <div className="bg-ilara-grey-light min-h-screen">
        <div className="flex mt-4">
          {/* Filter container */}
          <div className="flex my-4 px-8 justify-end items-center w-full">
            <label htmlFor="statusFilter" className="mr-2">
              Status:
            </label>
            <select
              id="statusFilter"
              className="border border-gray-300 rounded-md px-2 py-1"
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e?.target?.value);
              }}
            >
              <option value="">All</option>
              <option value={StoreStatusEnum?.PENDING}>Pending</option>
              <option value={StoreStatusEnum?.DONE}>Done</option>
            </select>
          </div>
        </div>
        <div className="flex mt-4">
          {/* Main container */}
          <div className="flex-[1_1_860px]">
            <InterviewTable filterByStatus={selectedStatus} />
            {/* Pass selectedStatus as a prop to InterviewTable for filtering */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default VirtualConsultations;
