import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const useStyles = makeStyles({
  avatar: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    width: '195px',
    transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '0px 20px'
  },
  openAvatar: {
    display: 'flex',
    alignItems: 'center',
    transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    margin: '0 auto'
  },
  name: {
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Satoshi, sans-serif',
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '-0.17px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    gap: '2px',
    lineHeight: '24px'
  },
  active: {
    margin: '0px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '-0.14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: 'inherit',
    display: 'block',
    opacity: '0.7'
  },
  margin: {
    display: 'flex',
    alignItems: 'center',
    transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    padding: '0px 10px',
    marginBottom: '0px'
  }
});

type Props = {
  open?: boolean;
  fullName: string;
  description?: string;
  show?: boolean;
  classname?: string;
  prefix?: string | null;
  bothInitials?: boolean;
  showIcon?: boolean;
  onClick?: (val: any) => void;
  textStyle?: any;
  containerWidth?: string;
  fontSize?: string;
  containerHeight?: string;
  testId?: string;
};

export default function CustomAvatar({
  open,
  fullName,
  classname,
  prefix,
  bothInitials,
  description,
  show,
  showIcon = false,
  textStyle = null,
  containerWidth,
  containerHeight,
  fontSize,
  testId
}: Props) {
  const classes = useStyles({ bothInitials });

  const initials =
    show || bothInitials
      ? fullName
          .split(' ')
          .slice(0, 2)
          .map((n) => n[0])
          .join('')
      : fullName?.charAt(0);

  return (
    <div
      data-testid={testId}
      className={
        open
          ? clsx(classes.avatar, classname)
          : show
          ? clsx(classes.margin, classname)
          : clsx(classes.openAvatar, classname)
      }
    >
      <Avatar
        sx={{
          backgroundColor: show || bothInitials ? '#D4DAF5' : '#5569D8',
          color: show || bothInitials ? '#2A45CD' : '',
          fontSize: fontSize ? fontSize : '16px',
          height: containerHeight ? containerHeight : '40px',
          width: containerWidth ? containerWidth : '40px',
          lineHeight: '40px',
          fontFamily: 'Inter',
          fontWeight: 500,
          textAlign: 'center',
          paddingTop: '1.5px',
          paddingLeft: '1px'
        }}
      >
        {initials?.toUpperCase()}
      </Avatar>
      {open || show ? (
        <div style={{ marginLeft: '11px' }} className="flex items-center">
          <div
            style={textStyle !== null ? textStyle : null}
            className={textStyle !== null ? '' : classes.name}
          >
            <span>{prefix} </span>
            <p className="font-secondary-bold font-bold text-sm">{fullName}</p>
          </div>
          <p className={classes.active}>
            {description}
            {showIcon ? (
              <KeyboardArrowDownOutlinedIcon sx={{ fontSize: '1.2em', verticalAlign: 'bottom' }} />
            ) : null}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
