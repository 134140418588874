import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'wouter';
import HandleErrorBoundary from './errors/ErrorHandler';
import NavBar from './Navbar';
import clsx from 'clsx';
import { capitalize } from '../tools/tools';
import { format } from 'date-fns';
import RoleGuard from '../routes/RoleGuard';
import CustomLoader from './CustomLoader';
import { useRecoilValue } from 'recoil';
import { patientEncounter } from '../tools/Atoms/atoms';
import { toLongDateTimeString } from '../tools/dateMethods';
import { smallTablet, tablet } from '../tools/designTokens';

type Props = {
  pageTitle?: string;
  children: React.ReactNode;
  subHeading?: string;
  encounterSection?: boolean;
  lastUpdated?: Date;
  doctor?: string;
  permission?: any;
  isPosPage?: boolean;
  isEncounterPage?: boolean;
  isLastSaveLoading?: boolean;
  showActionsOnly?: boolean;
} & (
  | {
      primaryAction: React.ReactNode;
      secondaryAction?: React.ReactNode;
    }
  | {
      primaryAction?: React.ReactNode;
      secondaryAction?: never;
    }
);

export default function PageLayout({
  pageTitle,
  children,
  primaryAction,
  secondaryAction,
  encounterSection = false,
  lastUpdated,
  doctor,
  permission,
  isPosPage = false,
  isEncounterPage = false,
  isLastSaveLoading = false,
  showActionsOnly = false
}: Props) {
  const [orderBanner, setOrderBanner] = useState(false);
  const [location] = useLocation();
  const patientData = useRecoilValue(patientEncounter);
  const {
    endAt,
    status,
    lastUpdatedBy: { fullName } = { fullName: null }
  } = patientData?.data?.data || {};

  const onClose = useCallback(() => {
    setOrderBanner(false);
  }, [setOrderBanner]);

  const isMediumScreen = useMediaQuery(`(max-width:${tablet})`);
  const belowMedium = useMediaQuery(`(max-width:${smallTablet})`);

  const date = new Date(lastUpdated);

  const formatedDoc = doctor
    ?.split(' ')
    ?.map((item) => capitalize(item))
    .join(' ');

  const Layout = () => (
    <div className="sticky top-16 z-10 w-full ">
      <div
        className={clsx(
          'flex justify-between items-center bg-white py-4 border-b border-b-solid border-ilara-grey-mid',
          (belowMedium && isPosPage) || isEncounterPage ? 'px-4' : 'px-8',
          isEncounterPage || isPosPage ? '' : 'mobile:flex-col'
        )}
      >
        <div>
          <h2
            data-testid="pageLayout-title"
            className="text-2xl smallTablet:text-xl w-fit font-secondary-black smallTablet:py-2 "
          >
            {pageTitle}
          </h2>
          <div className="flex flex-row space-x-2 items-center">
            <span data-testid="pageLayout-subHeading" className="text-ilara-secondary-text text-xs">
              {date && formatedDoc
                ? `Last saved automatically on ${format(date, 'dd MMM yyyy')} at ${format(
                    date,
                    'h:mm a'
                  )} by ${formatedDoc}`
                : null}
            </span>
            {isLastSaveLoading && <CustomLoader type="circular" />}
          </div>
        </div>
        {primaryAction || secondaryAction ? (
          <div className="flex space-x-4">
            {secondaryAction ?? null}
            {primaryAction ?? null}
          </div>
        ) : null}
      </div>
      {status === 'FINISHED' && isEncounterPage ? (
        <div
          className="bg-red-100 border text-xs text-red-700 font-medium px-4 py-3 cursor-pointer"
          role="alert"
        >
          <strong className="font-bold">Alert: </strong>
          <span className="block sm:inline">
            This encounter was closed on {toLongDateTimeString(endAt) || '-----'} by{' '}
            {fullName || '-----'}
          </span>
        </div>
      ) : null}
    </div>
  );

  const LayoutWithActions = () => (
    <div
      className={clsx(
        'bg-white z-10 tablet:px-4 sticky top-16 w-full py-4',
        'border-b border-b-solid border-ilara-grey-mid'
      )}
    >
      {primaryAction || secondaryAction ? (
        <div className="flex space-x-4">
          {secondaryAction ?? null}
          {primaryAction ?? null}
        </div>
      ) : null}
    </div>
  );

  const renderNavBar = () => {
    if (isEncounterPage || isPosPage) {
      return null;
    } else {
      if (encounterSection) {
        if (!isMediumScreen) {
          return <NavBar onClose={onClose} orderBanner={orderBanner} />;
        } else {
          return null;
        }
      } else {
        if (!belowMedium) {
          return <NavBar onClose={onClose} orderBanner={orderBanner} />;
        } else {
          return <div className="h-14"></div>;
        }
      }
    }
  };

  const renderLayout = () => {
    if (isEncounterPage) {
      if (isMediumScreen) {
        if (showActionsOnly) {
          return <LayoutWithActions />;
        }
        return null;
      } else {
        return <Layout />;
      }
    } else {
      if (isPosPage) {
        return <Layout />;
      } else {
        if (!isMediumScreen || !encounterSection) {
          return <Layout />;
        } else {
          return <LayoutWithActions />;
        }
      }
    }
  };

  useEffect(() => {
    if (location === '/inventory') {
      setOrderBanner(true);
    }
  }, [location]);

  return (
    <div>
      {!location.includes('/login') ? renderNavBar() : null}
      <HandleErrorBoundary key={location}>
        <div className={clsx(!isMediumScreen || !encounterSection ? 'w-full box-border' : '')}>
          {renderLayout()}
          {permission ? <RoleGuard permission={permission}>{children}</RoleGuard> : children}
        </div>
      </HandleErrorBoundary>
    </div>
  );
}
