import React, { useState } from 'react';
import logo from '../../assets/logo.svg';
import { CardMedia } from '@mui/material';
import { useLocation } from 'wouter';
import { useSetRecoilState } from 'recoil';
import { toastDataAtom } from '../../tools/Atoms/atoms';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IlaraInput from '../../components/IlaraInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../helper/axios';
import { ToastVariant } from '../../types';
import TwindButton from '../../components/TwindButton';

const EmailVerification = () => {
  const [, setLocation] = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const setToastData = useSetRecoilState(toastDataAtom);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is Required'),
      confirmPassword: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .nullable()
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        axiosInstance
          .post(`/passwordReset/resetPassword?password=${values.password}&token=${token} `)
          .then((response: any) => {
            setIsLoading(false);
            // localStorage.setItem('AUTH_TOKEN', response.data?.token);
            setToastData({
              open: true,
              variant: ToastVariant.success,
              title: 'Success',
              description: 'Password Reset Sucessfully!'
            });

            setLocation('/login');
          })
          .catch(() => {
            // If login failed
            setIsLoading(false);
            // Handle error, maybe display an error message
            setToastData({
              open: true,
              variant: ToastVariant.error,
              title: 'Error',
              description: 'Invalid/Expired Token!'
            });
          });
      } catch (error) {
        // console.error('Error occurred:', error);
        setIsLoading(false);
        setToastData({
          open: true,
          variant: ToastVariant.error,
          title: 'Error',
          description: 'Some error occured while Resetting Password!'
        });
        // Handle error, maybe display an error message
      }
    }
  });

  return !token ? (
    <div className="bg-ilara-blue-login flex flex-col items-center h-screen pt-24">
      <div className="flex items-center">
        <CardMedia sx={{ width: 100, height: 100 }} component="img" image={logo} alt="ilara logo" />
        <p className="ml-1 font-secondary-bold font-semibold text-3xl overflow-hidden whitespace-nowrap uppercase text-ilara-white">
          ILARA HEALTH
        </p>
      </div>
      <div className="bg-white p-8 rounded shadow-md w-full md:w-1/2 lg:w-1/3 mt-16">
        <h1 className="text-2xl font-bold mb-4 text-center">Email Sent SuccesFully!</h1>
      </div>
    </div>
  ) : (
    <div className="bg-ilara-blue-login flex flex-col items-center h-screen pt-24">
      <div className="flex items-center">
        <CardMedia sx={{ width: 100, height: 100 }} component="img" image={logo} alt="ilara logo" />
        <p className="ml-1 font-secondary-bold font-semibold text-3xl overflow-hidden whitespace-nowrap uppercase text-ilara-white">
          ILARA HEALTH
        </p>
      </div>
      <div className="bg-white p-8  rounded shadow-md w-full md:w-1/2 lg:w-1/3 mt-16">
        <h1 className="text-2xl font-bold mb-4 text-center">Reset Password</h1>
        <form>
          <div className="mb-6 relative">
            <IlaraInput
              placeholder="password"
              value={validation?.values?.password}
              name="password"
              type={showPassword ? 'text' : 'password'}
              onChange={validation?.handleChange}
              label="Password*"
              error={
                validation?.touched?.password && validation?.errors?.password
                  ? validation?.errors?.password
                  : null
              }
              className="tablet:w-[96%] smallTablet:mb-2 smallTablet:!w-full pr-10"
            />
            <div
              className={`absolute inset-y-0 right-0 flex items-center justify-center cursor-pointer mr-3 ${
                validation?.touched?.password && validation?.errors?.password ? 'mt-0' : 'mt-6'
              }`}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>
          <div className="mb-6 relative">
            <IlaraInput
              placeholder="Confirm password"
              value={validation?.values?.confirmPassword}
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={validation?.handleChange}
              label="Confirm Password*"
              error={
                validation?.touched?.confirmPassword && validation?.errors?.confirmPassword
                  ? validation?.errors?.confirmPassword
                  : null
              }
              className="tablet:w-[96%] smallTablet:mb-2 smallTablet:!w-full pr-10"
            />
            <div
              className={`absolute inset-y-0 right-0 flex items-center justify-center cursor-pointer mr-3 ${
                validation?.touched?.confirmPassword && validation?.errors?.confirmPassword
                  ? 'mt-0'
                  : 'mt-6'
              }`}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <TwindButton
              className={`bg-ilara-blue-login text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                isLoading ? 'opacity-50 pointer-events-none' : ''
              }`}
              onClick={(e) => {
                e?.preventDefault();
                validation?.handleSubmit();
              }}
              disabled={isLoading}
              variant="primary-login"
              loading={isLoading}
            >
              confirm{' '}
            </TwindButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailVerification;
