import React, { useEffect, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import CustomizedAccordion from '../../components/Accordion';
import { Link, useLocation } from 'wouter';
import axiosInstance from '../../helper/axios';
import { useSetRecoilState } from 'recoil';
import { toastDataAtom } from '../../tools/Atoms/atoms';
import { ToastVariant } from '../../types';
import { toLongDateTimeString } from '../../tools/dateMethods';
import Loading from '../../components/Loading';
import TwindButton from '../../components/TwindButton';
import { StoreStatusEnum } from '../../tools/constants';
const SymptomDetails = () => {
  const [, setLocation] = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const interviewId = queryParams.get('interviewId');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);
  const [data, setData] = useState<any>();
  function convertToTitleCase(str: string) {
    const triage = str
      ?.split('_') // Split the string by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with a space

    if (triage === 'Consultation') {
      return 'Consultation (Non Urgent)';
    } else if (triage === 'Consultation 24') {
      return 'Consultation (urgent)';
    } else {
      return triage;
    }
  }
  const setToastData = useSetRecoilState(toastDataAtom);
  useEffect(() => {
    setIsLoading(true);
    try {
      if (interviewId && !isLoading) {
        axiosInstance
          .get(`/interviews/${interviewId}`)
          .then((response: any) => {
            setIsLoading(false);
            setData(response?.data);
          })
          .catch(() => {
            // If login failed
            setIsLoading(false);
            // Handle error, maybe display an error message
            setToastData({
              open: true,
              variant: ToastVariant.error,
              title: 'Error',
              description: 'Some error occured while fetching interview details!'
            });
          });
      }
    } catch (error) {
      // console.error('Error occurred:', error);
      setIsLoading(false);
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while fetching interview details!'
      });
      // Handle error, maybe display an error message
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId]);
  const handleSubmit = () => {
    setIsLoadingChangeStatus(true);
    try {
      axiosInstance
        .put(
          `/interviews/updateStatus/${interviewId}/${
            data?.status === StoreStatusEnum?.PENDING ? 'Done' : 'Pending'
          }`
        )
        .then((response: any) => {
          setIsLoadingChangeStatus(false);
          if (response?.status === 200) {
            setLocation('/virtualConsultations');
          }
        })
        .catch(() => {
          // If login failed
          setIsLoadingChangeStatus(false);
          // Handle error, maybe display an error message
          setToastData({
            open: true,
            variant: ToastVariant.error,
            title: 'Error',
            description: 'Some error occured while changing status of interview!'
          });
        });
    } catch (error) {
      // console.error('Error occurred:', error);
      setIsLoadingChangeStatus(false);
      setToastData({
        open: true,
        variant: ToastVariant.error,
        title: 'Error',
        description: 'Some error occured while changing status of interview!'
      });
      // Handle error, maybe display an error message
    }
  };
  return (
    <PageLayout pageTitle="CONSULTATION SUMMARY">
      {/* Breadcrumbs */}
      <div className="bg-ilara-grey-light px-8 py-4">
        <Link to="/virtualConsultations">Virtual Consultation List</Link>
        <span>&nbsp;&gt;&nbsp;</span>
        <span className="text-ilara-blue">Consultation Summary</span>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="bg-ilara-grey-light min-h-screen px-8">
          <div className="flex mt-4">
            {/* Main container */}
            <div className="flex-[1_1_860px]">
              <div>
                <h1>Patient Details:</h1>
                <div className="flex items-center justify-between">
                  <div className="px-5">
                    <div className="flex mt-3">
                      <h2>Name:&nbsp;</h2> {data?.user?.name}
                    </div>
                    <div className="flex mt-1">
                      <h2>Age:&nbsp;</h2> {data?.user?.age}
                    </div>
                    <div className="flex mt-1">
                      <h2>Gender:&nbsp;</h2> {data?.user?.gender}
                    </div>
                    <div className="flex mt-1">
                      <h2>Telephone:&nbsp;</h2> +{data?.user?.contactNumber}
                    </div>
                    <div className="flex  mt-1 ">
                      <h2 className="contents">Virtual Consultation Time:</h2>{' '}
                      {toLongDateTimeString(new Date(data?.user?.insertedAt))}
                    </div>
                    <div className="flex mt-1">
                      <h2 className="contents">Triage Status:&nbsp;</h2>{' '}
                      {convertToTitleCase(data?.triageLevel?.triage_level)}
                    </div>
                    <div className="flex flex-row mt-1">
                      <h2 className="contents">Triage Description:&nbsp;</h2>{' '}
                      {data?.triageDescription ? data?.triageDescription : 'Null'}
                    </div>
                  </div>
                  <div>
                    <TwindButton
                      variant={data?.status === StoreStatusEnum?.PENDING ? 'primary' : 'danger'}
                      disabled={isLoadingChangeStatus}
                      loading={isLoadingChangeStatus}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {data?.status === StoreStatusEnum?.PENDING
                        ? 'Mark As Done'
                        : 'Mark As Pending'}
                    </TwindButton>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="mb-5">Conditions:</h1>
                <div className="px-5">
                  {data?.symptomsData?.map((element: any, index: number) => (
                    <div key={index} className={`${index > 0 ? 'mt-5' : null}`}>
                      <CustomizedAccordion
                        id="item-type"
                        summary={
                          <>
                            <div className="flex items-baseline">
                              <div className="w-16 bg-gray-100 rounded-full h-2.5 mb-4 dark:bg-gray-300">
                                <div
                                  className="bg-blue-600 h-2.5 rounded-full dark:bg-blue-500"
                                  style={{ width: `${Math.ceil(element?.probability * 100)}%` }}
                                  // style={{ width: `40%` }}
                                ></div>
                              </div>
                              <div className="mx-5">{(element?.probability * 100).toFixed(2)}%</div>
                            </div>

                            <h2>{element?.conditionName}</h2>
                            {/* <div className="text-ilara-grey-dark">Myocardial infarction</div> */}
                          </>
                        }
                        sx={{ width: '100%' }}
                      >
                        <div className="flex flex-wrap w-full flex-col items-start m-7">
                          <div className="p-8">
                            <h1 className="text-3xl font-semibold mb-6">Reasoning</h1>
                            <h2>Reason For</h2>
                            <h3 className="mt-2">Presence of symptoms:</h3>

                            {/* <h2 className="text-xl font-semibold mb-4">Overview</h2> */}
                            {/* <p>
                            A myocardial infarction, or heart attack, happens when the blood supply
                            to the heart muscle suddenly stops. The most common symptoms are:
                          </p> */}
                            <ul className="list-disc pl-6 mt-3">
                              {element?.supporting_evidence?.map((element: any, index: number) => (
                                <li key={index}>{element?.name}</li>
                              ))}
                              {/* <li>Chest pain, especially in the center or left part of the chest</li>
                            <li>Shortness of breath</li>
                            <li>Anxiety</li>
                            <li>Pain or discomfort in the upper body</li> */}
                            </ul>
                          </div>
                          <div className="p-8">
                            {/* <h1 className="text-3xl font-semibold mb-6">Findings Against:</h1> */}
                            <h2>Reason Against</h2>
                            <h3 className="mt-2">Absence of symptoms:</h3>

                            {/* <h2 className="text-xl font-semibold mb-4">Overview</h2> */}
                            {/* <p>
                            A myocardial infarction, or heart attack, happens when the blood supply
                            to the heart muscle suddenly stops. The most common symptoms are:
                          </p> */}
                            <ul className="list-disc pl-6 mt-3">
                              {element?.conflicting_evidence?.map((element: any, index: number) => (
                                <li key={index}>{element?.name}</li>
                              ))}
                              {/* <li>Chest pain, especially in the center or left part of the chest</li>
                            <li>Shortness of breath</li>
                            <li>Anxiety</li>
                            <li>Pain or discomfort in the upper body</li> */}
                            </ul>
                          </div>
                          {/* <div className="p-8">
                          <h2 className="text-xl font-semibold mb-4">Common care methods</h2>
                          <p className="mb-4">
                            How can people manage a myocardial infarction or heart attack?
                          </p>
                          <p>
                            Heart attacks are serious and can’t be treated at home. People who think
                            they or someone else is having a heart attack should call for an
                            ambulance right away. While they’re waiting for the ambulance to come,
                            it may help to:
                          </p>
                          <ul className="list-disc pl-6 mt-3">
                            <li>Loosen tight clothes</li>
                            <li>Sit down</li>
                            <li>Rest</li>
                            <li>Try to stay calm</li>
                            <li>Medication</li>
                          </ul>
                          <p className="mt-3">
                            What medicines can manage a myocardial infarction or heart attack?
                          </p>
                          <p>
                            While waiting for an ambulance, people may find it helps to take 300 mg
                            of aspirin.
                          </p>
                        </div>
                        <div className="p-8">
                          <h4 className="text-xl font-semibold mb-4">
                            What is a Myocardial Infarction or Heart Attack?
                          </h4>

                          <p className="mb-4">
                            A myocardial infarction or heart attack is a serious medical condition
                            that needs professional medical care right away. When people are having
                            a heart attack, they may feel:
                          </p>
                          <ul className="list-disc pl-6 mt-3">
                            <li>
                              Chest discomfort like a feeling of pain, pressure, heaviness,
                              tightness, or squeezing across the chest
                            </li>
                            <li>
                              Pain in other parts of the body that can spread from chest to arms
                              (usually the left arm, but it can be both arms), jaw, back, stomach,
                              and neck
                            </li>
                            <li>Dizzy</li>
                            <li>Sick</li>
                            <li>Anxious (like a panic attack)</li>
                            <li>People might also cough, wheeze, or sweat a lot</li>
                          </ul>
                          <p className="mt-4">
                            Women, people with diabetes, and the elderly are more likely to have
                            less-common symptoms like:
                          </p>
                          <ul className="list-disc pl-6 mt-3">
                            <li>Jaw, back, or throat pain</li>
                            <li>Indigestion or heartburn</li>
                            <li>Nausea or vomiting</li>
                            <li>Extreme tiredness</li>
                          </ul>
                        </div>
                        <div className="p-8">
                          <h4 className="text-xl font-semibold mb-4">
                            Causes of Myocardial Infarction or Heart Attack
                          </h4>

                          <p className="mb-4">
                            Heart attacks happen when the heart’s blood supply is suddenly blocked.
                            They’re often caused by:
                          </p>
                          <ul className="list-disc pl-6 mt-3">
                            <li>Coronary artery disease</li>
                            <li>
                              Severe tightening in the coronary artery, which sends blood to the
                              heart
                            </li>
                            <li>An artery blocked by a blood or cholesterol clot</li>
                          </ul>
                          <p className="mt-4">
                            Coronary artery disease occurs when the artery walls get filled with
                            plaque made of cholesterol and other substances, narrowing or tightening
                            the vessels. This results in the heart muscle starting to die when blood
                            and oxygen can’t get to it. Immediate treatment is crucial to prevent or
                            limit heart damage.
                          </p>
                        </div>
                        <div className="p-8">
                          <h4 className="text-xl font-semibold mb-4">
                            Diagnosis of Myocardial Infarction or Heart Attack
                          </h4>

                          <p className="mb-4">
                            Usually, healthcare providers diagnose heart attacks using several
                            methods:
                          </p>
                          <ul className="list-disc pl-6 mt-3">
                            <li>
                              Electrocardiogram (ECG): Quick and painless tests that measure the
                              heart’s electrical activity and confirm if a person has had a heart
                              attack. They can also identify the type of heart attack, aiding in
                              treatment decisions.
                            </li>
                            <li>
                              Blood sample testing for cardiac troponin: Measures leaked proteins
                              from heart cells into the blood to confirm the heart attack diagnosis,
                              assess heart damage, and monitor treatment effectiveness.
                            </li>
                            <li>
                              {`Echocardiograms: Scans using sound waves to visualize the heart's
                            interior, providing additional diagnostic information.`}
                            </li>
                            <li>
                              Coronary angiography: Helps healthcare providers identify blocked or
                              narrowed arteries, aiding in treatment planning.
                            </li>
                          </ul>
                        </div>
                        <div className="p-8">
                          <h2 className="text-xl font-semibold mb-4">Reasoning</h2>
                          <p className="mb-4">Reasons for Presence of symptoms:</p>
                          <ul className="list-disc pl-6 mt-3">
                            <li>Diagnosed hypertension</li>
                            <li>Smoking cigarettes</li>
                            <li>Headache</li>
                            <li>Fatigue</li>
                            <li>Widespread chest pain</li>
                            <li>Chest pain behind breastbone</li>
                            <li>Chest pain, spreading to the left upper limb</li>
                            <li>Chest pain spreading to between the shoulder blades...</li>
                          </ul>
                        </div> */}
                        </div>
                      </CustomizedAccordion>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default SymptomDetails;
