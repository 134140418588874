import React, { Suspense, useEffect } from 'react';
import LeftSidebar from './components/LeftSidebar';
import MainRoutes from './routes/routes';
import { useLoginInfo } from './hooks/useAppContext';
import CustomLoader from './components/CustomLoader';
import { useLocation } from 'wouter';

const MainLayout = () => {
  const loginInfo = useLoginInfo();
  const [location, setLocation] = useLocation();

  return (
    <div className="flex tablet:overflow-x-hidden ">
      {location === '/select-branch' ? (
        <div className="grow mb-8">
          <Suspense
            fallback={
              <div>
                <CustomLoader type="linear" />
              </div>
            }
          >
            <MainRoutes />
          </Suspense>
        </div>
      ) : (
        <>
          {!location.includes('/login') &&
            // !location.includes('/signup') &&
            !location.includes('/forgotpassword') &&
            !location.includes('/passwordReset/reset') && <LeftSidebar />}

          <div className="grow">
            <Suspense
              fallback={
                <div>
                  <CustomLoader type="linear" />
                </div>
              }
            >
              <MainRoutes />
            </Suspense>
          </div>
        </>
      )}
    </div>
  );
};

export default MainLayout;
