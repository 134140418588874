import React from 'react';
import PageLayout from '../../components/PageLayout';
import UsersTable from '../../components/Tables/UsersTable';
import TwindButton from '../../components/TwindButton';
import { useLocation } from 'wouter';

const UsersList = () => {
  const [, setLocation] = useLocation();

  return (
    <PageLayout pageTitle="Users List">
      <div className="bg-ilara-grey-light min-h-screen">
        <div className="flex mt-4">
          {/* Filter container */}
          <div className="flex my-4 px-8 justify-end items-center w-full">
            <TwindButton
              type="button"
              onClick={() => {
                setLocation('addUser');
              }}
              variant="primary"
            >
              Add User
            </TwindButton>
          </div>
        </div>
        <div className="flex mt-4">
          {/* Main container */}
          <div className="flex-[1_1_860px]">
            <UsersTable />
            {/* Pass selectedStatus as a prop to InterviewTable for filtering */}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default UsersList;
